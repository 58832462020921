<template>
  <div>
    <section class="main-banner">
      <div class="outer">
        <div class="inner">
          <div class="main-bg">
            <div class="container">
              <div class="row">
                <div class="col-xs-12 col-lg-6 data">
                  <div class="data__title">
                    Choosing the good food is the best investment in you.
                  </div>
                  <button class="btn primary-btn" @click="$emit('orderNow')">
                    Order Now!
                  </button>
                  <div class="data__launch-slider">
                    <div class="data__launch-slider__info">
                      <div class="data__launch-slider__info__title">
                        TODAY LAUNCH
                      </div>
                      <div class="data__launch-slider__info__sub-title">
                        Protein+
                      </div>
                    </div>
                    <div class="data__launch-slider__meals">
                      <div class="data__launch-slider__meals__item">
                        <a href="#" title="protien meal">
                          <img
                            src="../../../../assets/images/imgs/meal.png"
                            alt="protien meal"
                          />
                          <div>Meal one ></div>
                        </a>
                      </div>
                      <div class="data__launch-slider__meals__item">
                        <a href="#" title="protien meal">
                          <img
                            src="../../../../assets/images/imgs/meal.png"
                            alt="protien meal"
                          />
                          <div>Meal one ></div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="steps">
      <div class="container">
        <div class="row workflow">
          <div class="col-xs-12 col-lg-12">
            <div class="workflow__title">How it works</div>
          </div>
          <div class="col-xs-12 col-sm-6 col-lg-3">
            <div class="workflow__details">
              <div class="workflow__details__icons">
                <i class="workflow__details__icons__numb icon-num1"></i>
                <i class="workflow__details__icons__step icon-Cooking-ic"></i>
              </div>
              <div class="workflow__details__text">
                <div class="workflow__details__text__title">you choose</div>
                <div class="workflow__details__text__description">
                  Cras eu elit congue, placerat dui ut, tincidunt nisl. Nulla
                  leo elit, pharetra bibendum justo quis
                </div>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-lg-3">
            <div class="workflow__details">
              <div class="workflow__details__icons">
                <i class="workflow__details__icons__numb icon-num1"></i>
                <i class="workflow__details__icons__step icon-Cooking-ic"></i>
              </div>
              <div class="workflow__details__text">
                <div class="workflow__details__text__title">you choose</div>
                <div class="workflow__details__text__description">
                  Cras eu elit congue, placerat dui ut, tincidunt nisl. Nulla
                  leo elit, pharetra bibendum justo quis
                </div>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-lg-3">
            <div class="workflow__details">
              <div class="workflow__details__icons">
                <i class="workflow__details__icons__numb icon-num1"></i>
                <i class="workflow__details__icons__step icon-Cooking-ic"></i>
              </div>
              <div class="workflow__details__text">
                <div class="workflow__details__text__title">you choose</div>
                <div class="workflow__details__text__description">
                  Cras eu elit congue, placerat dui ut, tincidunt nisl. Nulla
                  leo elit, pharetra bibendum justo quis
                </div>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-lg-3">
            <div class="workflow__details">
              <div class="workflow__details__icons">
                <i class="workflow__details__icons__numb icon-num1"></i>
                <i class="workflow__details__icons__step icon-Cooking-ic"></i>
              </div>
              <div class="workflow__details__text">
                <div class="workflow__details__text__title">you choose</div>
                <div class="workflow__details__text__description">
                  Cras eu elit congue, placerat dui ut, tincidunt nisl. Nulla
                  leo elit, pharetra bibendum justo quis
                </div>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-lg-12 workflow__main-btn">
            <button class="btn primary-btn ">
              Order Now!
            </button>
          </div>
        </div>
      </div>
    </section>
    <div class="seprator"></div>
    <section id="faq" class="faq">
      <div class="leftSide"></div>
      <div class="rightSide"></div>

      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-lg-12 ">
            <div class="faq__title text-center primary-white-title">FAQ</div>

            <!-- <div class="accordion">
              <div class="accordion__item active">
                <div class="accordion__item__question">
                  <div class="ask">
                    <span>1</span>Tristique posuere velit nisi blandit magna,
                  </div>
                  <div class="toggle-icon">
                    <span class="plus">+</span><span class="minus">-</span>
                  </div>
                </div>
                <div class="accordion__item__answer">
                  Quisque suscipit ipsum est, eu venenatis leo ornare eget. Ut
                  porta facilisis elementum. Sed condimentum sed massa quis
                  ullamcorper. Donec at scelerisque neque. Pellentesque
                  sagittis, massa sodales sodales finibus, felis ligula tempus
                  lorem, eu porttitor ex lacus vel felis. Vivamus egestas
                  scelerisque consectetur. Vestibulum faucibus enim urna,
                  fermentum aliquet quam euismod non. Proin cursus, libero quis
                  semper elementum, diam metus eleifend magna, in placerat sem
                  velit vitae lacus. Vivamus tempor fringilla nisl, id
                  condimentum felis dignissim nec.
                </div>
              </div>
              <div class="accordion__item">
                <div class="accordion__item__question">
                  <div class="ask">
                    <span>1</span>Tristique posuere velit nisi blandit magna,
                  </div>
                  <div class="toggle-icon">
                    <span class="plus">+</span><span class="minus">-</span>
                  </div>
                </div>
                <div class="accordion__item__answer">
                  Quisque suscipit ipsum est, eu venenatis leo ornare eget. Ut
                  porta facilisis elementum. Sed condimentum sed massa quis
                  ullamcorper. Donec at scelerisque neque. Pellentesque
                  sagittis, massa sodales sodales finibus, felis ligula tempus
                  lorem, eu porttitor ex lacus vel felis. Vivamus egestas
                  scelerisque consectetur. Vestibulum faucibus enim urna,
                  fermentum aliquet quam euismod non. Proin cursus, libero quis
                  semper elementum, diam metus eleifend magna, in placerat sem
                  velit vitae lacus. Vivamus tempor fringilla nisl, id
                  condimentum felis dignissim nec.
                </div>
              </div>
              <div class="accordion__item">
                <div class="accordion__item__question">
                  <div class="ask">
                    <span>1</span>Tristique posuere velit nisi blandit magna,
                  </div>
                  <div class="toggle-icon">
                    <span class="plus">+</span><span class="minus">-</span>
                  </div>
                </div>
                <div class="accordion__item__answer">
                  Quisque suscipit ipsum est, eu venenatis leo ornare eget. Ut
                  porta facilisis elementum. Sed condimentum sed massa quis
                  ullamcorper. Donec at scelerisque neque. Pellentesque
                  sagittis, massa sodales sodales finibus, felis ligula tempus
                  lorem, eu porttitor ex lacus vel felis. Vivamus egestas
                  scelerisque consectetur. Vestibulum faucibus enim urna,
                  fermentum aliquet quam euismod non. Proin cursus, libero quis
                  semper elementum, diam metus eleifend magna, in placerat sem
                  velit vitae lacus. Vivamus tempor fringilla nisl, id
                  condimentum felis dignissim nec.
                </div>
              </div>
              <div class="accordion__item">
                <div class="accordion__item__question">
                  <div class="ask">
                    <span>1</span>Tristique posuere velit nisi blandit magna,
                  </div>
                  <div class="toggle-icon">
                    <span class="plus">+</span><span class="minus">-</span>
                  </div>
                </div>
                <div class="accordion__item__answer">
                  Quisque suscipit ipsum est, eu venenatis leo ornare eget. Ut
                  porta facilisis elementum. Sed condimentum sed massa quis
                  ullamcorper. Donec at scelerisque neque. Pellentesque
                  sagittis, massa sodales sodales finibus, felis ligula tempus
                  lorem, eu porttitor ex lacus vel felis. Vivamus egestas
                  scelerisque consectetur. Vestibulum faucibus enim urna,
                  fermentum aliquet quam euismod non. Proin cursus, libero quis
                  semper elementum, diam metus eleifend magna, in placerat sem
                  velit vitae lacus. Vivamus tempor fringilla nisl, id
                  condimentum felis dignissim nec.
                </div>
              </div>
            </div> -->
     <template>
  <v-expansion-panels>
    <v-expansion-panel
      v-for="(item,i) in 5"
      :key="i"
    >
      <v-expansion-panel-header>
        Item
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
          </div>
        </div>
      </div>
    </section>

    <section class="newsletter">
      <div class="newsletter__text">
        <div class="newsletter__text__title">
          Subscribe to newsletter
        </div>
        <div class="newsletter__text__subtitle">
          Get updates about our new and daily meals
        </div>
        <div class="newsletter__text__input">
          <input type="text" placeholder="Email Address" />
          <button class="primary-btn btn">Subscribe</button>
        </div>
      </div>
    </section>

    <section class="about-us">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xs-12 col-lg-9 text-center">
            <div class="about-us__title ">About us</div>
            <div class="about-us__big__subtitle">
              Tristique posuere velit nisi blandit magna,
            </div>
            <div class="about-us__subtitle">
              Donec dapibus mauris id odio ornare tempus. Duis sit amet accumsan
              justo, quis tempor ligula. Quisque quis pharetra felis. Ut quis
              consequat orci, at consequat felis. Suspendisse auctor laoreet
              placerat. Nam et risus non lacus dignissim lacinia sit amet nec
              eros. Nulla vel urna quis libero pharetra varius. Nulla tellus
              nunc, malesuada at scelerisque eget, cursus at eros. Maecenas
              pellentesque lacus quis erat eleifend sagittis. Sed vel maximus
              ante, quis mattis neque. Nullam dapibus erat sed nulla cursus
              accumsan. Nulla volutpat libero lacinia venenatis sodales. Ut in
              pellentesque velit.
            </div>
            <div class="about-us__subtitle">
              Sed vel maximus ante, quis mattis neque. Nullam dapibus erat sed
              nulla cursus accumsan. Nulla volutpat libero lacinia venenatis
              sodales. Ut in pellentesque velit.
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  created() {
    console.log(this.$store.getters["auth/getMyProfile"]);
    console.log(this.$store.getters["auth/getProfile"]);
  },
  computed: {
    windowWidth() {
      return this.$store.getters.getAppWindowWidth;
    },
  },
};
</script>

<style scoped>
/* .page1 {
  height: 100vh;
  background-image: url("../../../../assets/images/img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.row {
  height: inherit;
}

.col {
  height: inherit;
}

.col1 {
  padding-left: 13%;
}

.p1c1 {
  padding-top: 20%;
  font-size: 30px;
  text-align: left;
}

.p1b1 {
  background-color: #F26722;
  border-radius: 25px;
  border: none;
  width: 180px;
  height: 42px;
  color: black;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition: 0.3s;
}

.p1b1:hover {
  background-color: #fcec3c;
  box-shadow: 0px 0px 15px #fcec3c;
  transition: 0.3s;
}

.p1b1:focus {
  border: none;
  outline: none;
}

.p1c2 {
  float: left;
}

#leftCol1 {
  background-image: url("../../../../assets/images/Img-1.png");
  background-repeat: no-repeat;
  background-size: 80% 100%;
  background-position: 150px 50px;
  width: 100%;
}

#orderBtn:hover {
  background-color: blue;
}

.page2 {
  height: 400px;
  background-color: #282e49;
  width: 100%;
  top: 0;
  left: 0;
}

.p2c1 {
  color: white;
  text-align: left;
  padding-top: 10%;
}

#leftCol2 {
  background-image: url("../../../../assets/images/Meals.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  max-width: 100%;
  height: inherit;
}

#p1c4 {
  border-radius: 30px;
  height: 230px;
  width: 430px;
  background-color: #ffffff;
  box-shadow: 0px 10px 30px #8d8d8d;
  border: none;
} */
</style>
