
import state from './mealState.js'
import * as mutations from './mealMutations.js'
import * as actions from './mealActions.js'
import * as getters from './mealGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}