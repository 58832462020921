<template>
  <div class="footer">
         <div class="first-div">
            <img
              src="../../../assets/images/imgs/Logo.png"
              alt="logo"
              height="77"
              width="250"
            />
            <div class="text">
              Donec dapibus mauris id odio ornare tempus. Duis sit amet accumsan
              justo, quis tempor ligula. Quisque quis pharetra felis. Ut quis
              consequat orci, at consequat felis. Suspendisse auctor laoreet
              placerat. Nam et risus non lacus dignissim lacinia sit amet nec
            </div>
          </div>
    <div class="container">
      <!-- <v-footer padless>
      <v-col
        class="text-center"
        cols="12"
      >
        {{ new Date().getFullYear() }} — Built by Xapprika.  <strong>V-78</strong>
      </v-col>
    </v-footer> -->

      <div class="row">

        <div class="col-xs-12 col-sm-6 col-lg-6">
          <div class="footer-menu">
            <h5>Brisk</h5>
            <ul>
              <li><a href="javascript:void(0)">Today meals</a></li>
              <li><a href="javascript:void(0)">About us</a></li>
              <li><a href="javascript:void(0)">How it works</a></li>
              <li><a href="javascript:void(0)">Privacy policy</a></li>
              <li><a href="javascript:void(0)">Plans</a></li>
              <li><a href="javascript:void(0)">Terms and conditions</a></li>
            </ul>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-lg-6">
          <div class="footer-menu">
            <h5>Contact Us</h5>
            <div class="phone">(745)511-7476</div>
            <div class="email">Support@brisk.com</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.first-div {
  background: #f8fafc;
}
.footer-menu {
  margin-left: 50px;
}
.footer-menu h5 {
  color: #000;
  font-weight: bold;
  margin-bottom: 30px;
}
.footer-menu ul {
  padding-left: 0 !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.footer-menu ul li {
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 20px;
}
.footer-menu ul li a {
  color: #000;
  text-decoration: none;
}
</style>
