<template>
  <div id="nav" class="header">
    
    <v-toolbar dense>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Admin</v-toolbar-title>

      <v-spacer></v-spacer>

      <span class="px-6"><router-link to="/">Client</router-link></span>

      <span class="px-6"><router-link to="/admin">Admin</router-link></span>

    </v-toolbar>
  </div>
</template>

<script>
export default {
  computed: {
    drawer: {
      set(val) {
        this.$store.dispatch('admin/setAppDrawer', val)
      },
      get() {
        return this.$store.getters['admin/getAppDrawer']
      }
    }
  }
}
</script>

<style>

</style>