<template>
  <v-card>
    <v-dialog
      v-model="deleteWarning"
      width="500"
    >
      <v-card>
        <v-card-title class="headline lighten-2">
          Delete warning
        </v-card-title>

        <v-card-text>
          Do you really want to delete this order?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="requesting"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-btn
            v-else
            color="error"
            text
            @click="deleteOrder()"
          >
            Yes
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="deleteWarning = false"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
    </v-card-title>

    <OrderTable
      :orders="allOrders"
      :search="search"
      :requesting="requesting"
      @view="viewOrder"
      @delete="deleteOrderWarning"
    />
  </v-card>
</template>

<script>
import OrderService from '@/services/orderService'

import OrderTable from '../Shared_Components/Orders/OrdersTable'

export default {
  components: {
    OrderTable
  },
  created() {
    console.log('orders table created.')

    this.setupComponent()
  },
  props: {
    orders: {
      type: Array,
      required: false,
      default: () => { return [] }
    },
    customOrders: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      orderService: new OrderService(),
      search: '',
      allOrders: [],
      requesting: false,
      deleteWarning: false,
      order: {}
    }
  },
  watch: {
    orders() {
      this.setupComponent()
    }
  },
  methods: {
    setupComponent() {
      if ((this.orders && this.orders[0]) || this.customOrders) {
        this.allOrders = this.orders
      } else {
        this.getOrders()
      }
    },
    viewOrder(order) {
      console.log(order)
      if (order.key) {
        this.$router.push(`/admin/order/${order.key}`)
      }
    },
    deleteOrderWarning(order) {
      console.log(order)
      this.order = order
      this.deleteWarning = true
    },
    deleteOrder() {
      if (this.order && this.order.key) {
        this.requesting = true

        this.orderService
          .deleteOrder(this.order.key, this.order)
          .then(data => {
            this.requesting = false

            this.allOrders = this.allOrders.filter(a => a.key !== this.order.key)
            this.deleteWarning = false
          })
          .catch(error => {
            this.requesting = false
            console.log(error)
          })

      }
    },
    getOrders() {
      this.allOrders = []
      this.requesting = true

      this.orderService
        // .getLimitedMeals(this.itemsPerPage)
        .getOrders()
        .then(data => {
          console.log(data)
          this.requesting = false
          this.allOrders = data
        })
        .catch(error => {
          this.requesting = false
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>

tr {
  height: 100px;
}

td {
  padding: 0px 0px;
}

td img {
  height: 100%;
}

</style>