
const getters = {
  getAppDrawer(state) {
    return state.appDrawer
  },
  
  getErrors: state => {
    return state.errors
  },
  
  getLoading: state => {
    return state.loading
  }
}

export default getters