
export const getUploading = state => {
  return state.uploading
}

export const getUploadProgress = state => {
  return state.uploadProgress
}

export const getProgressList = state => {
  return state.progressList
}