<template>
  <div class="pt-2 fullHeight">
    <div class="row" style="margin: 0px 12px;">
      <div class="col col-lg-12 col-sm-12" style="padding: 3px 12px;">
        <span style="font-size: 22px;">Additional Snacks</span>
      </div>
    </div>
    <div class="row" style="margin: 0px 12px;">
      <div class="col col-lg-12 col-sm-12" style="padding: 3px 12px;">
        <span class="c1">Optional and not included in the meal price!</span>
      </div>
    </div>
    <div class="row" style="margin: 0px 12px;">
      <div class="col col-lg-12 col-sm-12" style="padding: 3px 12px;">
        <v-tabs color="black" centered v-model="tab">
          <v-tabs-slider color="#F26722"></v-tabs-slider>
          <v-tab>Snacks</v-tab>
          <v-tab>DRINKS</v-tab>
          <v-tab>BULK MEALS</v-tab>
          <v-tab>EXTRA MEALS</v-tab>
        </v-tabs>
      </div>
    </div>
    <div class="row center-slider-custom" v-if="tab === 0">
      <div
        v-for="(element, index) in thisSnacks"
        :key="index"
        class="col-lg-2 col-md-3 col-sm-6 col-xs-12"
        style="padding: 3px 10px;"
      >
        <div class="card">
          <img class="cloriesImg" src="@/assets/images/Calories.png" />
          <img style="padding-top: 10px;" src="@/assets/images/Image 18.png" />
          <br />
          <span class="diet_title">{{ element.title }}</span>
          <br />
          <span class="diet_details_header"
            >{{ element.price * element.quantity }} EGP</span
          >
          <br />
          <button class="button addSub" @click="sub(index)">-</button>
          <div class="quanitity">
            <span>{{ element.quantity }}</span>
          </div>
          <button class="button addSub" @click="add(index)">+</button>
          <br />
          <button class="button choose" @click="chooseSnack(index)">ADD</button>
        </div>
      </div>
    </div>
    <div class="row center-data " style="margin: 0px 12px;">
      <div class="c2">
        Selected items are going to be delivered at the first day of the meal
        plan
      </div>
      <div class="c3" @click="$emit('skip')">Skip</div>
    </div>

    <div class="bts-position">
      <div class="btns-wrapper">
        <div class="selected-type-wrapper">
          <span class="selectedDietType">Selected Diet</span>
          <br />
          <span class="selectedDietType1">{{ dietType }}</span>
          <br />
          <div class="selectedDietType2" @click="$emit('resetDietType')">
            <span>Change</span>
            <img src="@/assets/images/arrow-ic-35.png" class="pl-2" />
          </div>
        </div>
        <button class="p1b1" @click="choosen()">
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["dietType", "snacks"],
  data: () => ({
    tab: null,
    thisSnacks: [
      {
        id: 1,
        title: "Nuts Pack 100gm",
        price: 60,
        quantity: 0,
      },
      {
        id: 2,
        title: "Nuts Pack 100gm",
        price: 60,
        quantity: 0,
      },
      {
        id: 3,
        title: "Nuts Pack 100gm",
        price: 60,
        quantity: 0,
      },
      {
        id: 4,
        title: "Nuts Pack 100gm",
        price: 60,
        quantity: 0,
      },
      {
        id: 5,
        title: "Nuts Pack 100gm",
        price: 60,
        quantity: 0,
      },
    ],
    choosenSnacks: [],
  }),
  created() {
    if (this.snacks && this.snacks[0]) {
      this.snacks.forEach((snack) => {
        if (snack.id) {
          if (snack.index) {
            this.thisSnacks[snack.index] = snack;
            this.choosenSnacks.push(snack.index);
          } else {
            let index = this.thisSnacks.findIndex((a) => a.id === snack.id);
            this.thisSnacks[index] = snack;
            this.choosenSnacks.push(index);
          }
        }
      });
    }
  },
  mounted() {
    this.adjustCalorieImages();
  },
  computed: {
    windowWidth() {
      return this.$store.getters.getAppWindowWidth;
    },
    windowHeight() {
      return this.$store.getters.getAppWindowHeight;
    },
  },
  watch: {
    tab(val) {
      console.log(val);

      if (val === 0) {
        this.adjustCalorieImages();
      }
    },
    windowWidth(val) {
      // console.log(val)

      this.adjustCaloriesImageByWidth();
      this.adjustCaloriesImageByHeight();
    },
    windowHeight(val) {
      // console.log(val)

      this.adjustCaloriesImageByHeight();
    },
  },
  methods: {
    adjustCalorieImages() {
      setTimeout(() => {
        this.adjustCaloriesImageByWidth();
        this.adjustCaloriesImageByHeight();
      }, 50);
    },
    adjustCaloriesImageByWidth() {
      let a = document.getElementsByClassName("card"),
        imgs = document.getElementsByClassName("cloriesImg");

      for (let i = 0; i <= imgs.length; i++) {
        if (a[i]) {
          let b = a[i].getBoundingClientRect();
          imgs[i].style.left = b.left + 120 + "px";
          // imgs[i].style.top = b.top - 10 + 'px'
          imgs[i].style.y = b.y + "px";
        }
      }
    },
    adjustCaloriesImageByHeight() {
      let a = document.getElementsByClassName("card"),
        imgs = document.getElementsByClassName("cloriesImg");

      for (let i = 0; i <= imgs.length; i++) {
        if (a[i]) {
          let b = a[i].getBoundingClientRect();
          // imgs[i].style.top = b.top + 50 + 'px'
          imgs[i].style.y = b.y + "px";
        }
      }
    },
    choosen() {
      let thisSnacks = this.choosenSnacks.map((a) => {
        return {
          ...this.thisSnacks[a],
          index: a,
        };
      });
      console.log(thisSnacks);
      this.$emit("addSnacks", thisSnacks);
    },
    chooseSnack(index) {
      if (
        this.thisSnacks[index].quantity &&
        !this.choosenSnacks.includes(index)
      )
        this.choosenSnacks.push(index);
      console.log(this.choosenSnacks);
    },
    add(index) {
      this.thisSnacks[index].quantity++;
    },
    sub(index) {
      if (this.thisSnacks[index].quantity > 0)
        this.thisSnacks[index].quantity--;
    },
  },
};
</script>

<style scoped>
.c1 {
  font-size: 14px;
  color: #818181;
}

.diet_title {
  font-size: 13px;
  text-align: center;
}

.diet_details_header {
  font-size: 15px;
  color: #5ecbf0;
}

.c2 {
  font-size: 13px;
  color: #818181;
}

.c3 {
  cursor: pointer;
  color: #f26722;
}

.card {
  width: 100%;
  height: 100%;
  background-color: #f7f9fc;
  border-radius: 20px;
  cursor: pointer;
  text-align: center;
}

.cloriesImg {
  position: absolute;
}

.button {
  background-color: white;
  color: black;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0px;
  cursor: pointer;
}

.button:focus {
  outline: 0;
}

.addSub {
  border: 2px solid #e8e8e8;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.quanitity {
  display: inline-block;
  padding: 10px;
}

.quanitity span {
  background-color: #e8e8e8;
  padding: 2px 8px 3px 8px;
  border-radius: 9px;
}

.choose {
  border: 2px solid #f26722;
  border-radius: 10px;
  width: 70%;
}

.center-slider-custom {
  justify-content: center;
  display: flex;
  margin-bottom: 20px;
}
.center-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
