<template>
  <v-app id="app">
    <router-view name="header"></router-view>

    <fade-transition origin="center" mode="out-in" :duration="250">
      <div id="content" ref="div1">
        <router-view />
      </div>
    </fade-transition>

    <router-view name="footer"></router-view>
  </v-app>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";

import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    FadeTransition,
  },
  data: () => ({
    divWidth: null,
    divHeight: null,
  }),
  created() {
    console.log(this.$i18n.locale, this.$vuetify.rtl, this.$route.path);
    window.addEventListener("resize", this.myEventHandler);

    firebase.auth().onAuthStateChanged((user) => {
      console.log(user);
      if (user) {
        this.$store.dispatch("auth/setFirebaseProfile", {
          ...user.providerData[0],
          uid: user.uid,
        });
      } else {
        this.$store.dispatch("auth/setFirebaseProfile", null);
        this.$store.dispatch("auth/setMyProfile", null);
      }
    });
  },
  mounted() {
    this.divWidth = this.$refs.div1.clientWidth;
    this.divHeight = this.$refs.div1.clientHeight;
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  watch: {
    $route(to, from) {
      console.log("Router changed: ", to);
    },
    divWidth(val) {
      // console.log(val)
      this.$store.dispatch("setAppWindowWidth", val);
    },
    divHeight(val) {
      // console.log(val)
      this.$store.dispatch("setAppWindowHeight", val);
    },
  },
  methods: {
    myEventHandler(e) {
      // your code for handling resize...
      this.divWidth = this.$refs.div1.clientWidth;
      this.divHeight = this.$refs.div1.clientHeight;
    },
  },
};
</script>

<style>
#app {
  font-family: Lato;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

/* #nav {
  padding: 30px;
} */

/* #nav a {
  font-weight: bold;
  color: #2c3e50;
} */

#nav a.router-link-exact-active {
  color: #42b983;
}

.app {
  font-family: Lato;
}
html {
  font-size: 16px;
}
</style>
