
export const MY_PROFILE = (state, value) => {
  state.myProfile = value
}

export const FIREBASE_PROFILE = (state, value) => {
  state.firebaseProfile = value
}

export const MY_TOKEN = (state, value) => {
  state.myToken = value
}

export const MY_REFRESH_TOKEN = (state, value) => {
  state.myRefreshToken = value
}

export const NEXT_REFRESH_TIME = (state, value) => {
  state.sendNextRefreshRequestIn = value
}