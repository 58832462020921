import { ordersCollection, storageRef } from '@/config/firebase'
import store from '../store'

export default class OrderService {

  getMyOrders() {
    return new Promise(function(resolve, reject) {
      if (store.getters['auth/getProfile'] && store.getters['auth/getProfile'].key) {
        try {
          store.dispatch('admin/addLoading')
          ordersCollection
            .where("user", "==", store.getters['auth/getProfile'].key)
            .get()
            .then((querySnapshot) => {
              let orders = []
              querySnapshot.forEach((doc) => {
                store.dispatch('admin/subLoading')
  
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                orders.push({
                  ...doc.data(),
                  key: doc.id
                })
              });
              // console.log(orders)
              resolve(orders)
            })
            .catch((error) => {
              store.dispatch('admin/subLoading')
  
              console.log("Error getting documents: ", error);
              reject('No such document!')
            });
  
          
        } catch (err) {
          console.log(err)
          store.dispatch('admin/subLoading')
          reject(err)
        }
      } else {
        reject("User not found.")
      }
    })
  }

  getOrderByOrderId(orderId) {
    return new Promise(function(resolve, reject) {
      try {
        store.dispatch('admin/addLoading')
        ordersCollection
          .where("orderId", "==", orderId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              store.dispatch('admin/subLoading')

              // doc.data() is never undefined for query doc snapshots
              console.log(doc.id, " => ", doc.data());
              let order = {
                ...doc.data(),
                key: doc.id
              }
              resolve(order)
            });
          })
          .catch((error) => {
            store.dispatch('admin/subLoading')

            console.log("Error getting documents: ", error);
            reject('No such document!')
          });

        
      } catch (err) {
        console.log(err)
        store.dispatch('admin/subLoading')
        reject(err)
      }
    })
  }

  getOrder(key) {
    return new Promise(async function(resolve, reject) {
      try {
        store.dispatch('admin/addLoading')
        const doc = await ordersCollection
          .doc(key)
          .get()

        store.dispatch('admin/subLoading')
        if (doc.exists) {
          resolve(doc.data())
        } else {
          reject('No such document!')
        }
        
      } catch (err) {
        console.log(err)
        store.dispatch('admin/subLoading')
        reject(err)
      }
    })
  }

  getOrders() {
    return new Promise(async function(resolve, reject) {
      try {
        store.dispatch('admin/addLoading')
        const data = await ordersCollection
          .get()

        let orders = []
        data.forEach(doc => {
          orders.push({...doc.data(), key: doc.id})
        })
        // console.log(orders);

        store.dispatch('admin/subLoading')
        resolve(orders)
      } catch (err) {
        console.log(err)
        store.dispatch('admin/subLoading')
        reject(err)
      }
    })
  }
  
  updateOrder(key, order) {
    return new Promise(async function(resolve, reject) {
      try {
        store.dispatch('admin/addLoading')
        ordersCollection
          .doc(key)
          .set(order, { merge: true })
          .then(() => {
            store.dispatch('admin/subLoading')
            // console.log("Document written with ID: ", docRef.id);
            resolve()
          })
          .catch(function(error) {
            store.dispatch('admin/subLoading')
            store.dispatch('admin/addError', error)
            // console.error("Error adding document: ", error);
            reject(error)
          });
      } catch (err) {
        store.dispatch('admin/subLoading')
        store.dispatch('admin/addError', err)
        console.log(err)
        reject(err)
      }
    })
  }
  
  saveOrder(order) {
    return new Promise(async function(resolve, reject) {
      try {
        store.dispatch('admin/addLoading')
        ordersCollection
          .add(order)
          .then(function(docRef) {
            store.dispatch('admin/subLoading')
            // console.log("Document written with ID: ", docRef.id);
            resolve(docRef.id)
          })
          .catch(function(error) {
            store.dispatch('admin/subLoading')
            store.dispatch('admin/addError', error)
            // console.error("Error adding document: ", error);
            reject(error)
          });
      } catch (err) {
        store.dispatch('admin/subLoading')
        store.dispatch('admin/addError', err)
        console.log(err)
        reject(err)
      }
    })
  }
  
  deleteOrder(key, order) {
    return new Promise(async function(resolve, reject) {
      try {
        store.dispatch('admin/addLoading')
        await ordersCollection
          .doc(key)
          .delete();
          
        store.dispatch('admin/subLoading')
        resolve()

      } catch (err) {
        store.dispatch('admin/subLoading')
        store.dispatch('admin/addError', err)
        console.log(err)
        reject(err)
      }
    })
  }

}