<template>
  <div>
    <Main 
      v-if="currentPage === 'home' || currentPage === 'main'"
      @orderNow="orderNow"
    />
    <OrderNow
      v-else-if="currentPage === 'orderNow'"
      @main="main"
    />

  </div>
</template>

<script>
import Main from './Pages/Main'
import OrderNow from './Pages/OrderNow'

export default {
  components: {
    Main,
    OrderNow
  },
  computed: {
    currentPage() {
      return this.$store.getters['client/getCurrentPage']
    }
  },
  methods: {
    main() {
      this.$store.dispatch('client/setCurrentPage', 'main')
    },
    orderNow() {
      this.$store.dispatch('client/setCurrentPage', 'orderNow')
    }
  }
}
</script>

<style scoped>
</style>