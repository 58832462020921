<template>
  <div class="pt-5 fullHeight">
    Needs login
    <v-tabs color="black" centered v-model="tab">
      <v-tabs-slider color="#F26722"></v-tabs-slider>
      <v-tab>Login</v-tab>
      <v-tab>Signup</v-tab>
    </v-tabs>
    <LoginForm
      v-if="tab === 0"
      @requesting="requestingLogin1"
      @userCredentials="loginUser"
    />
    <SignupForm
      v-else
      @requesting="requestingSignup1"
      @signup-success="signupSuccess"
    />
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";

import SignupForm from "@/app/Client/Shared_Components/SignupForm";
import LoginForm from "@/app/Client/Shared_Components/LoginForm";

import { usersCollection } from "@/config/firebase";

export default {
  components: {
    SignupForm,
    LoginForm,
  },
  data: () => ({
    tab: 0,
    requestingLogin: false,
    requestingSignup: true,
    user: {},
  }),
  methods: {
    requestingLogin1(val) {
      this.requestingLogin = val;
    },
    requestingSignup1(val) {
      this.requestingSignup = val;
    },
    loginUser({ email, password }) {
      if (email && password) {
        firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
          .then(() => {
            // console.log("Connection is made persistent of type local.")

            // Try to sigin
            console.log(email, password);
            firebase
              .auth()
              .signInWithEmailAndPassword(email, password)
              .then((result) => {
                console.log(result);
                if (result && result.user && result.user.uid) {
                  // this below will be done in App.vue
                  // this.$store.dispatch('auth/setFirebaseProfile', {
                  //   ...result.user.providerData[0],
                  //   uid: result.user.uid
                  // })

                  if (this.user && this.user.key) {
                    if (!this.user.uid || this.user.uid !== result.user.uid) {
                      this.user.uid = result.user.uid;
                    }

                    this.$store.dispatch("auth/setMyProfile", this.user);
                    this.$emit("user", this.user);
                  } else {
                    usersCollection
                      .doc(result.user.uid)
                      .get()
                      .then((doc) => {
                        if (doc.exists) {
                          console.log("Document data:", doc.data());

                          this.$store.dispatch("auth/setMyProfile", {
                            ...doc.data(),
                            key: doc.id,
                          });
                          this.$emit("user", {
                            ...doc.data(),
                            key: doc.id,
                          });
                        } else {
                          // doc.data() will be undefined in this case
                          console.log("No such document!");
                        }
                      })
                      .catch((error) => {
                        console.log("Error getting document:", error);
                      });
                  }
                } else {
                  console.log("Unable to login the user.");
                }
              })
              .catch(function(error) {
                // Handle Errors here.
                console.log(error.code, error.message);
              });
          })
          .catch(function(error) {
            // Handle Errors here.
            console.log(error.code, error.message);
          });
      }
    },
    signupSuccess(user) {
      this.user = user;
      if (user.email && user.password) {
        this.loginUser({ email: user.email, password: user.password });
      }
    },
  },
};
</script>

<style></style>
