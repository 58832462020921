<template>
  <div class="calendar">
    <div class="row">
      <div class="col col-lg-4">
        <img style="float: right;" src="@/assets/images/arrow-ic-26.png" />
      </div>
      <div class="col col-lg-4">
        {{ months[monthInView].toUpperCase() }} {{ yearInView }}
      </div>
      <div class="col col-lg-4">
        <img style="float: left;" src="@/assets/images/arrow-ic-27.png" />
      </div>
    </div>

    <div :key="calendarKey">
      <div class="row" id="daysRow">
        <div class="col col-lg-12" style="padding: 4px 12px;">
          <span
            style="padding: 10px;"
            v-for="(day, index) in days"
            :key="index"
          >
            {{ day.substring(0, 3).toUpperCase() }}
          </span>
        </div>
      </div>

      <div class="row" v-for="i in [0, 1, 2, 3, 4, 5]" :key="i">
        <div class="col col-lg-12" style="padding: 4px 12px;">
          <div
            @click="selectDate(i, j, $event)"
            :class="dates[i][j] + ` ${i}-${j}` + ' date ' + getClass(i, j)"
            style="display: inline-block; margin: 0px 16px;"
            v-for="(day, j) in days"
            :key="j"
          >
            {{ dates[i][j] }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    availableDates: {
      type: Array,
      required: false,
    },
  },
  created() {
    console.log("Calendar created ", this.availableDates);

    this.setupComponent();
  },
  mounted() {
    this.calendarKey++;
  },
  data: () => ({
    today: 0,
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    monthInView: 0,
    yearInView: 0,
    days: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    dayInView: 0,
    daysInOldMonth: 0,
    daysInThisMonth: 0,
    dates: [],
    selectedI: null,
    selectedJ: null,
    selectedDate: "",
    calendarKey: 0,
  }),
  watch: {
    selectedI(val) {
      console.log(val);
    },
    selectedJ(val) {
      console.log(val);
      this.selectedDate = `${this.yearInView}-${this.monthInView + 1}-${
        this.dates[this.selectedI][val]
      }`;
      this.$emit("selectedDate", {
        year: this.yearInView,
        month: this.monthInView,
        date: this.dates[this.selectedI][val],
        day: this.selectedJ,
        fullDate: this.selectedDate,
      });
    },
  },
  methods: {
    setupComponent() {
      let now = new Date();
      this.today = now.getDate();
      // this.today = 1
      this.monthInView = now.getMonth();
      // this.monthInView = 3
      this.yearInView = now.getFullYear();

      this.setupComponentDate();
    },
    setupComponentDate() {
      let firstDate = new Date(this.yearInView, this.monthInView, 1),
        day = firstDate.getDay(),
        thisMonth = new Date(this.yearInView, this.monthInView + 1, 0),
        daysInThisMonth = thisMonth.getDate(),
        oldMonth = new Date(this.yearInView, this.monthInView, 0), // look out for month here, its telling old month instead of this month
        daysInOldMonth = oldMonth.getDate();
      console.log(
        firstDate,
        day,
        thisMonth,
        daysInThisMonth,
        oldMonth,
        daysInOldMonth
      );

      this.dayInView = day;
      this.daysInOldMonth = daysInOldMonth;
      this.daysInThisMonth = daysInThisMonth;

      let week = [],
        difference = this.dayInView - 1;

      for (let h = 0; h < 6; h++) {
        week = [];

        for (let i = 0; i < this.days.length; i++) {
          // console.log(h, i, i == this.dayInView)

          let toBe = 0;

          if (h === 0) {
            toBe = i - difference;
            // console.log(toBe)
            if (toBe > 0) {
              week.push(toBe);
            } else {
              week.push(this.daysInOldMonth + toBe);
            }
          } else {
            toBe = 7 * h - difference + i;

            if (toBe <= this.daysInThisMonth) {
              week.push(toBe);
            } else {
              week.push(toBe - this.daysInThisMonth);
            }
          }

          // console.log(week)
        }
        this.dates.push(week);
      }
      console.log(this.dates);
    },
    selectDate(i, j, event) {
      // console.log(event)
      // console.log(i, j, `${this.yearInView}-${this.monthInView + 1}-${this.dates[i][j]}`, this.availableDates)

      if (
        event &&
        event.target &&
        event.target.className &&
        !event.target.className.includes("disabled")
      ) {
        if (
          !(j === 5 || j === 6) &&
          this.availableDates.includes(
            `${this.yearInView}-${this.monthInView + 1}-${this.dates[i][j]}`
          )
        ) {
          // Checking previous occurences for duplciate
          let occured = false;

          for (let k = 0; k < i; k++) {
            if (this.dates[k].includes(this.dates[i][j])) {
              let docs = document.getElementsByClassName(this.dates[i][j]);
              // console.log(docs)
              if (docs.length) {
                docs.forEach((doc) => {
                  // console.log(doc.className)
                  if (doc.className.includes(`${k}-${j}`)) {
                    if (!doc.className.includes("disabled")) {
                      occured = true;
                    }
                  }
                });
              } else {
                occured = true;
                break;
              }
            }
          }
          if (!occured) {
            this.selectedI = i;
            this.selectedJ = j;
          }
        }
      }
    },
    getClass(i, j) {
      let date = `${this.yearInView}-${this.monthInView + 1}-${
        this.dates[i][j]
      }`;
      var addClass = "";
      // console.log(this.today, i, j, document.getElementsByClassName(`${i}-${j}`))

      if (this.today === this.dates[i][j]) {
        let occured = false;
        if (this.today > 7) {
          // if today is greater than 7 but the row is first then its the same date from old month
          if (i === 0) {
            // if first row, then make it occured so it won't be highlighted
            occured = true;
          } else {
            // if its 2nd occurrence then don't check in the first row
            for (let k = 1; k < i; k++) {
              if (this.dates[k].includes(this.today)) {
                occured = true;
                break;
              }
            }
          }
        } else {
          for (let k = 0; k < i; k++) {
            if (this.dates[k].includes(this.today)) {
              occured = true;
              break;
            }
          }
        }

        // for (let k = 0; k < i; k++) {
        //   if (this.dates[k].includes(this.today)) {
        //     occured = true
        //     break
        //   }
        // }
        if (!occured) {
          addClass = "today";
        }
      }

      try {
        if (
          j === 5 ||
          j === 6 ||
          (i === 0 && // if row is first so if it has greater than 7 then obviously its of previous month
            this.dates[i][j] > 7) ||
          (i === 4 && this.dates[i][j] < 20) ||
          (i === 5 && this.dates[i][j] < 20) ||
          this.dates[i][j] < this.today
        ) {
          addClass = addClass.concat(" disabled");
        }

        if (this.selectedI === i && this.selectedJ === j) {
          return "selectedDate " + addClass;
        } else if (
          this.availableDates.includes(date) &&
          !addClass.includes("disabled")
        ) {
          let occured = false;
          // console.log(i, j)
          for (let k = 0; k < i; k++) {
            if (
              date &&
              date.split("-") &&
              date.split("-")[2] &&
              parseInt(date.split("-")[2]) &&
              this.dates[k].includes(parseInt(date.split("-")[2]))
            ) {
              let docs = document.getElementsByClassName(
                parseInt(date.split("-")[2])
              );
              // console.log(docs)
              if (docs.length) {
                docs.forEach((doc) => {
                  // console.log(doc.className)
                  if (doc.className.includes(`${k}-${j}`)) {
                    if (!doc.className.includes("disabled")) {
                      occured = true;
                    }
                  }
                });
              } else {
                occured = true;
                break;
              }
            }
          }
          if (!occured) {
            return "blueBorder " + addClass;
          } else {
            return "" + addClass;
          }
        } else {
          return "" + addClass;
        }
      } catch (e) {
        return "blackBorder " + addClass;
      }
    },
  },
};
</script>

<style scoped>
.calendar {
  /* background-color: gray; */
  width: 100%;
  height: 320px;
  padding: 20px 10px;
  margin: 0 auto;
}

#daysRow {
  height: 40px;
  background-color: #f7f9fc;
  border-radius: 30px;
}

.date {
  width: 26px;
  height: 26px;
  cursor: pointer;
  border-radius: 50%;
}

.today {
  border: 2px solid #ff8c00;
}

.blackBorder {
  border: 2px solid black;
}

.blueBorder {
  border: 2px solid #5ecbf0;
}

.selectedDate {
  background-color: #f26722;
  border: 2px solid #f26722;
}

.disabled {
  color: #e8e8e8;
}
</style>
