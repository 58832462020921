<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="snacksToShow"
      :search="search"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
    >
      
      <template
        v-slot:body="{ items }"
      >
        <tbody>
          <tr
            v-for="item in items"
            :key="item.name"
          >
            <td>{{ item.index }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.quantity }}</td>
            <td>{{ item.price * item.quantity }}</td>
          </tr>
        </tbody>
      </template>

    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    snacks: {
      required: true
    },
    search: {
      type: String,
      required: false,
      default: ''
    },
    requesting: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      page: 1,
      itemsPerPage: 5,
      pageCount: null,
      headers: [
        {
          text: 'Index',
          align: 'left',
          sortable: false,
          value: 'index',
        },
        { text: 'Title', align: 'left', value: 'title' },
        { text: 'Quantity', align: 'left', value: 'quantity' },
        { text: 'Total Price', align: 'left' }
      ],
      snacksToShow: []
    }
  },
  watch: {
    snacks(val) {
      console.log(val)
      this.setupDataTable(val)
    },
    page(val) {
      // console.log(val)
    },
    pageCount(val) {
      // console.log(val)
    }
  },
  methods: {
    setupDataTable(snacks) {
      if (snacks && Array.isArray(snacks)) {
        this.snacksToShow = []
        
        snacks.forEach(snack => {
          this.snacksToShow.push(snack)
        });
      }
    }
  }
}
</script>

<style>

</style>