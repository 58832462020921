
import state from './adminState'
import getters from './adminGetters'
import mutations from './adminMutations'
import actions from './adminActions'

import meal from './meal'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {
    meal
  }
}