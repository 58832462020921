<template>
  <div>

    <div class="row" v-for="(key, index) in Object.keys(user)" :key="index">
      <div class="col col-lg-2 col-sm-4 left">
        <strong>{{ key }}</strong>
      </div>
      <div class="col col-lg-10 col-sm-8 left">
        {{ user[key] }}
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  watch: {
    user(val) {
      console.log(val)
    }
  }
}
</script>

<style>

</style>