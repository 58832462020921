<template>
  <div>

    <HomeDrawer/>

    <router-view name="header"></router-view>

    <h1>Admin</h1>

    <v-container>
      <fade-transition origin="center" mode="out-in" :duration="250">
        <div id="content">
          <router-view/>
        </div>
      </fade-transition>
    </v-container>

    <router-view name="footer"></router-view>
    
  </div>
</template>

<script>
import { FadeTransition } from "vue2-transitions";
import HomeDrawer from "./Drawer/HomeDrawer"

export default {
  components: {
    FadeTransition,
    HomeDrawer
  }
}
</script>

<style>

</style>