
const getters = {
  getAppWindowWidth(state) {
    return state.appWindowWidth
  },
  getAppWindowHeight(state) {
    return state.appWindowHeight
  }
}

export default getters