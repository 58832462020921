<template>
  <div class="pt-5 fullHeight">
    <div class="row" style="margin: 0px 12px;">
      <div class="col col-lg-12 col-sm-12" style="padding: 3px 12px;">
        <span style="font-size: 22px;">Target Calories</span>
      </div>
    </div>
    <div class="row" style="margin: 0px 12px;">
      <div class="col col-lg-12 col-sm-12" style="padding: 3px 12px;">
        <span class="c1">Select the target calories you want in your diet</span>
      </div>
    </div>
    <br />
    <br />
    <br />
    <div class="row" style="margin: 0px 12px;">
      <div class="col col-lg-12 col-sm-12" style="padding: 3px 12px;">
        <v-radio-group
          v-model="thisTargetCalories"
          class="radioTargetCalories"
          row
        >
          <div class="card">
            <div class="card1" id="0">
              <img id="info1" src="@/assets/images/info-ic-1.png" />
              <div class="hide" id="hide1">
                <span class="hideTitle"
                  >This calories range is recommended for</span
                >
                <br />
                <p class="hideText">
                  This calories range is recommended forThis calories range is
                  recommended forThis calories range is recommended for
                </p>
              </div>
              <div class="cardText">
                <v-radio color="#fcec3c" label="1200 ~ 1500" value="0">
                </v-radio>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card1" id="1">
              <img id="info2" src="@/assets/images/info-ic-1.png" />
              <div class="hide" id="hide2">
                <span class="hideTitle"
                  >This calories range is recommended for</span
                >
                <br />
                <p class="hideText">
                  This calories range is recommended forThis calories range is
                  recommended forThis calories range is recommended for
                </p>
              </div>
              <div class="cardText">
                <v-radio color="#fcec3c" label="1500 ~ 2000" value="1">
                </v-radio>
              </div>
            </div>
          </div>
        </v-radio-group>
      </div>
    </div>

    <div class="bts-position">
      <div class="btns-wrapper">
        <div class="selected-type-wrapper">
          <div class="selectedDietType">Selected Diet</div>
          <div class="selectedDietType1">{{ dietType }}</div>
          <div class="selectedDietType2" @click="$emit('resetDietType')">
            <span>Change</span>
            <img src="@/assets/images/arrow-ic-35.png" class="pl-2" />
          </div>
        </div>
        <button
          class="p1b1"
          @click="$emit('setTargetCalories', thisTargetCalories)"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["dietType", "targetCalories"],
  data() {
    return {
      thisTargetCalories: null,
    };
  },
  created() {
    if (this.targetCalories) {
      this.thisTargetCalories = this.targetCalories;
      this.setBorder();
    }
  },
  mounted() {
    this.clearAllBorders();
  },
  watch: {
    thisTargetCalories(val) {
      // console.log(val)
      this.clearAllBorders();
      this.setBorder();
    },
  },
  methods: {
    clearAllBorders() {
      let a = document.getElementsByClassName("card1");
      a.forEach((element) => {
        // console.log(element)
        element.style.border = "2px solid #F26722";
      });
    },
    setBorder() {
      let a = document.getElementById(this.thisTargetCalories);
      if (a) {
        a.style.border = "2px solid #F26722";
      }
    },
  },
};
</script>

<style scoped>
.c1 {
  font-size: 14px;
  color: #818181;
}

.radioTargetCalories {
  padding-left: 30%;
}

.card {
  width: 30%;
  height: 50px;
  cursor: pointer;
}

.card1 {
  width: 90%;
  height: 100%;
  background-color: #f7f9fc;
  border-radius: 15px;
  border: 2px solid #f7f9fc;
}

.cardText {
  width: 70%;
  margin: 0 auto;
  padding-top: 5%;
}

#info1 {
  position: absolute;
  left: 23%;
  top: -30%;
}

#info2 {
  position: absolute;
  left: 53%;
  top: -30%;
}

.hide {
  width: 300px;
  height: 100px;
  background-color: white;
  box-shadow: 0px 0px 15px rgb(226, 226, 226);
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  padding: 10px;
  display: none;
}

.hideTitle {
  color: #fcec3c;
  font-size: 12px;
}

.hideText {
  color: #818181;
  font-size: 12px;
}

#hide1 {
  left: 28%;
}

#info1:hover + #hide1 {
  display: block;
  transition: 1s;
}

#hide2 {
  left: 58%;
}

#info2:hover + #hide2 {
  display: block;
  transition: 1s;
}
@media (max-width: 1200px) {
  .radioTargetCalories {
    padding-left: 0;
  }
  .card {
    width: 50%;
    height: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 992px) {
  #info2,
  #info1 {
    display: none;
  }
}
@media (max-width: 480px) {
  .card {
    width: 100%;
    margin-bottom: 10px;
  }
  .btns-wrapper {
    width: 100%;
    flex-wrap: wrap;
  }
}
</style>
