
export const setUploading = ({ commit }, value ) => {
  if (typeof value === 'boolean') {
    commit('UPLOADING', value)
  }
}

export const setUploadProgress = ({ commit }, value ) => {
  if (typeof value === 'number') {
    commit('UPLOAD_PROGRESS', value)
  }
}

export const setProgressList = ({ commit, getters }, list ) => {
  if (Array.isArray(list)) {
    commit('PROGRESS_LIST', list)
  }
}

export const setProgressInList = ({ commit, getters }, { value, index } ) => {
  if (typeof value === 'number' && typeof index === 'number') {
    let a = [...getters.getProgressList]
    if (a.length > index) {
      a[index] = value
      commit('PROGRESS_LIST', a)
    }
  }
}