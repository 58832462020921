<template>
  <div class="pa-3">
    <v-row no-gutters >
      <v-col
        cols="12"
        lg="12"
        sm="12"
      >
        <v-text-field
          label="Email"
          :rules="[required, emailAddress]"
          v-model="email"
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row no-gutters >
      <v-col
        cols="12"
        lg="12"
        sm="12"
      >
        <v-text-field
          label="Password"
          :rules="[required, min6Chars]"
          v-model="password"
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row no-gutters >
      <v-col
        cols="12"
        lg="12"
        sm="12"
      >
        <v-progress-circular
          v-if="requesting"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <v-btn
          v-else
          depressed
          color="primary"
          @click="login"
        >
          Login
        </v-btn>
      </v-col>
    </v-row>

  </div>
</template>

<script>

export default {
  data: () => ({
    email: '',
    password: '',
    requesting: false
  }),
  watch: {
    requesting(val) {
      if (val) {
        this.$emit('requesting', val)
      }
    }
  },
  methods: {
    // Rules
    required: value => {
      return !!value || 'Required'
    },
    min6Chars: value => {
      return (value && value.length >= 6) || 'Min 6 characters'
    },
    emailAddress: value => {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(value) || 'Invalid email address'
    },

    login() {
      if (
        this.password && 
        this.email &&
        this.emailAddress(this.email)
      ) {
        this.$emit('userCredentials', {
          email: this.email,
          password: this.password
        })
      }
    }
  }
}
</script>

<style>

</style>