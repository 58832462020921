<template>
  <div class="pt-5 fullHeight">
    <div class="row" style="margin: 0px 12px;">
      <div class="col col-lg-12 col-sm-12" style="padding: 3px 12px;">
        <span style="font-size: 22px;">Choose Meals</span>
      </div>
    </div>
    <div class="row" style="margin: 0px 12px;">
      <div class="col col-lg-12 col-sm-12" style="padding: 3px 12px;">
        <span class="c1"
          >It’s better if we maintain all your daily meal if you want to control
          your </span
        ><span class="c2">CALORIES!</span>
      </div>
    </div>
    <div class="row" style="margin: 0px 12px;">
      <div class="col col-lg-12 col-sm-12" style="padding: 3px 12px;">
        <div class="row radioChooseMeal" style="margin: 0px 12px;">
          <div class="col-lg-3 col-sm-4 col-xs-12">
            <div class="checkCard" @click="toggleThisMeal('Breakfast')">
              <div class="checkCard1" id="Breakfast">
                <img id="info2" src="@/assets/images/checkmark-ic-320.png" />
                <div class="checkCardText">
                  <span>Breafast</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-4 col-xs-12">
            <div class="checkCard" @click="toggleThisMeal('Lunch')">
              <div class="checkCard1" id="Lunch">
                <img id="info2" src="@/assets/images/checkmark-ic-320.png" />
                <div class="checkCardText">
                  <span>Lunch</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-4 col-xs-12">
            <div class="checkCard" @click="toggleThisMeal('Dinner')">
              <div class="checkCard1" id="Dinner">
                <img id="info2" src="@/assets/images/checkmark-ic-320.png" />
                <div class="checkCardText">
                  <span>Dinner</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin: 0px 12px;">
      <div class="col col-lg-12 col-sm-12" style="padding: 3px 12px;">
        <span style="font-size: 22px;">Choose Days</span>
      </div>
    </div>
    <div class="row" style="margin: 0px 12px;">
      <div class="col col-lg-12 col-sm-12" style="padding: 3px 12px;">
        <span class="c1"
          >Choose how many days you want to receive your thisMeals during this
          month</span
        >
      </div>
    </div>
    <div class="row" style="margin: 0px 12px;">
      <div class="col col-lg-12 col-sm-12" style="padding: 3px 12px;">
        <v-radio-group v-model="thisMealDays" class="radioChooseMealDays " row>
          <div class="row">
            <div class="col-lg-3 col-sm-6 col-xs-12">
              <div class="card">
                <div class="card1" id="3">
                  <div class="cardText">
                    <v-radio
                      color="#fcec3c"
                      class="px-4"
                      label="3 Days"
                      value="3"
                    >
                    </v-radio>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-xs-12">
              <div class="card">
                <div class="card1" id="5">
                  <div class="cardText">
                    <v-radio
                      color="#fcec3c"
                      class="px-4"
                      label="5 Days"
                      value="5"
                    >
                    </v-radio>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-xs-12">
              <div class="card">
                <div class="card1" id="10">
                  <div class="cardText">
                    <v-radio
                      color="#fcec3c"
                      class="px-4"
                      label="10 Days"
                      value="10"
                    >
                    </v-radio>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-xs-12">
              <div class="card">
                <div class="card1" id="20">
                  <div class="cardText">
                    <v-radio
                      color="#fcec3c"
                      class="px-4"
                      label="20 Days"
                      value="20"
                    >
                    </v-radio>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-radio-group>
      </div>
    </div>
    <div class="row calculations" style="margin: 0px 12px;">
      <div class="col col-lg-4 col-sm-12" style="padding: 3px 12px;">
        <div class="row pb-2">
          <div class="col col-lg-12 col sm-12">
            <span style="color: #818181; float:left">Price per day</span>
            <span style="color: #818181; float:right">{{ pricePerDay }}</span>
          </div>
        </div>
        <hr />
        <div class="row py-2">
          <div class="col col-lg-12 col sm-12">
            <span style="color: #818181; float:left"
              >Total Price ( {{ thisMealDays }} )</span
            >
            <span style="color: #818181; float:right">{{
              thisMealDays * pricePerDay
            }}</span>
          </div>
        </div>
        <hr />
      </div>
    </div>
    <div class="bts-position">
      <div class="btns-wrapper">
        <div class="selected-type-wrapper">
          <span class="selectedDietType">Selected Diet</span>
          <br />
          <span class="selectedDietType1">{{ dietType }}</span>
          <br />
          <div class="selectedDietType2" @click="$emit('resetDietType')">
            <span>Change</span>
            <img src="@/assets/images/arrow-ic-35.png" class="pl-2" />
          </div>
        </div>
        <button
          class="p1b1"
          @click="
            $emit('setMeals', {
              mealTimes: thisMeals,
              mealDays: thisMealDays,
            })
          "
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["dietType", "mealTimes", "mealDays", "pricePerDay"],
  data: () => ({
    thisMeals: [],
    thisMealDays: null,
  }),
  created() {
    if (this.mealTimes && this.mealTimes[0]) this.thisMeals = this.mealTimes;
    if (this.mealDays) this.thisMealDays = this.mealDays.toString();
  },
  mounted() {
    this.clearMealStyles();
    this.clearAllBorders();
  },
  watch: {
    thisMeals(val) {
      this.clearMealStyles();

      val.forEach((element) => {
        let a = document.getElementById(element);
        a.style.backgroundColor = "#fcec3c";
        a.children[0].style.display = "inline";
      });
    },
    thisMealDays(val) {
      // console.log(val)
      this.clearAllBorders();
      this.setBorder();
    },
  },
  methods: {
    toggleThisMeal(meal) {
      let index = this.thisMeals.findIndex((a) => a === meal);
      if (index >= 0) {
        this.thisMeals.splice(index, 1);
      } else {
        this.thisMeals.push(meal);
      }
      // console.log(this.thisMeals, meal, index)
    },
    clearMealStyles() {
      let a = document.getElementsByClassName("checkCard1");
      a.forEach((b) => {
        b.style.backgroundColor = "#f26722";
        b.children[0].style.display = "none";
      });
    },
    clearAllBorders() {
      let a = document.getElementsByClassName("card1");
      a.forEach((element) => {
        // console.log(element)
        element.style.border = "2px solid #F7F9FC";
      });
    },
    setBorder() {
      let a = document.getElementById(this.thisMealDays);
      if (a) {
        a.style.border = "2px solid #f26722";
      }
    },
  },
};
</script>

<style scoped>
.c1 {
  font-size: 14px;
  color: #818181;
}

.c2 {
  font-size: 14px;
  color: #f7dc05;
}

.radioChooseMeal {
  display: flex;
  justify-content: center;
}

/* .radioChooseMealDays {
  padding-left: 20%;
} */

.card {
  /* width: 20%; */
  height: 45px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.card1 {
  width: 80%;
  height: 100%;
  background-color: #f7f9fc;
  border-radius: 15px;
  border: 2px solid #f7f9fc;
}

.cardText {
  width: 100%;
  margin: 0 auto;
  padding-top: 5%;
}

.checkCard {
  width: 100%;
  height: 50px;
  cursor: pointer;
}

.checkCard1 {
  /* width: 90%; */
  height: 100%;
  background-color: #f7f9fc;
  border-radius: 15px;
  display: flex;
  align-items: center;
}

.checkCard1 img {
  width: 35px;
  float: left;
  padding-left: 10px;
}

.checkCardText {
  width: 100%;
  margin: 0 auto;
  /* padding-top: 5%; */
  font-size: 20px;
  padding-left: 10%;
}
/* @media (max-width: 480px) {
  .card {

  }
  .btns-wrapper {
   
  }
} */
.radioChooseMealDays .row {
  justify-content: center;
}
.calculations {
  justify-content: center;
}
</style>
