<template>
  <div class="pt-5 fullHeight">
    <p class="a7c1">
      Do You Want To Choose Your Meal Or Let The Chief Do That For You?
    </p>

    <br />
    <br />
    <div class="row" style="margin: 0px 12px;">
      <div class="col col-lg-6 col-sm-6" style="padding: 3px 12px;">
        <button
          class="p1b1"
          style="float: right;"
          @click="$emit('mealSelection', 1)"
        >
          Choose Meals
        </button>
      </div>
      <div class="col col-lg-6 col-sm-6" style="padding: 3px 12px;">
        <button
          class="p1b1"
          style="float: left; background-color: white; border: 2px solid #F26722; color: #F26722;"
          @click="$emit('mealSelection', 2)"
        >
          Chief Selections
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({}),
  computed: {
    windowWidth() {
      return this.$store.getters.getAppWindowWidth;
    },
    windowHeight() {
      return this.$store.getters.getAppWindowHeight;
    },
  },
  methods: {},
};
</script>

<style scoped>
.a7c1 {
  font-size: 20px;
  text-align: center;
  width: 35%;
  margin: auto;
  padding-top: 18%;
}
</style>
