<template>
  <div class=" pt-5">
    <div class="footer">
      <v-footer padless>
        <v-col
          class="text-center"
          cols="12"
        >
          {{ new Date().getFullYear() }} — <strong>Admin Home Footer</strong>
        </v-col>
      </v-footer>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>