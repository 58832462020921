<template>
  <div>

    <h1>HomePage</h1>

  </div>
</template>

<script>

export default {
}
</script>

<style>

</style>