<template>
  <div>
    <h1>Meal form</h1>
    
    <div id="errors">
      <v-alert
        v-for="(element, index) in errorMessages"
        :key="index"
        :type="element.type"
        :border="element.border"
        dense
      >
        <strong>{{ element.name }}</strong>&nbsp;&nbsp;&nbsp;{{ element.message }}
      </v-alert>
    </div>

    <div class="form">
      <div class="row">
        <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <v-text-field
            label="Name"
            v-model="meal.mealName"
            :rules="[required, min3Chars]"
            hide-details="auto"
            :disabled="disabledArr.includes('mealName')"
          ></v-text-field>
        </div>
      </div>

      <div class="row">
        <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="row">
            <div class="col col-lg-8 col-md-8 col-sm-8 col-xs-12">
              <v-text-field
                label="Ingredient"
                v-model="ingredient"
                hide-details="auto"
              ></v-text-field>
            </div>
            <div class="col col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <v-btn 
                @click="addIngredient"
                depressed
                :disabled="disabledArr.includes('ingredients')"
              >
                Add
              </v-btn>
            </div>
          </div>
        </div>
        <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <v-file-input
            multiple
            accept="image/*"
            label="Add Images"
            @change="onFileSelect"
            :disabled="disabledArr.includes('image')"
          ></v-file-input>
        </div>
      </div>

      <div class="row" v-if="(images && images.length) || (ingredients && ingredients.length)">
        <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <ul>
            <li
              v-for="(element, index) in ingredients"
              :key="index"
              class="mb-5"
            >
              {{ element }}
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="red"
                style="float: right;"
                @click="ingredients.splice(index, 1)"
              >
                <v-icon dark>
                  mdi-minus
                </v-icon>
              </v-btn>
            </li>
          </ul>
        </div>
        <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <ul>
            <li
              v-for="(element, index) in images"
              :key="index"
              class="mb-5"
            >
              {{ getFileName(element.name) }}
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="red"
                style="float: right;"
                @click="images.splice(index, 1)"
              >
                <v-icon dark>
                  mdi-minus
                </v-icon>
              </v-btn>
            </li>
          </ul>
        </div>
      </div>

      <div class="row">
        <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <v-select
            v-model="meal.mealType"
            :items="items"
            label="Meal Type"
            :rules="[required]"
            :disabled="disabledArr.includes('mealType')"
          ></v-select>
        </div>
        <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <v-file-input
            single
            accept="image/*"
            label="Add Nutritional Facts Images"
            @change="onFileSelect1"
            :disabled="disabledArr.includes('image1')"
          ></v-file-input>
        </div>
      </div>
      
      <div class="row">
        <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <v-text-field
            label="Calories Low"
            v-model="meal.caloriesLow"
            :rules="[required, num]"
            hide-details="auto"
            :disabled="disabledArr.includes('calLow')"
          ></v-text-field>
        </div>
        <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <v-text-field
            label="Calories High"
            v-model="meal.caloriesHigh"
            :rules="[required, num]"
            hide-details="auto"
            :disabled="disabledArr.includes('calHigh')"
          ></v-text-field>
        </div>
      </div>

      <br />
      <br />
      <div class="row">
        <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="meal.dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-combobox
                v-model="meal.dates"
                multiple
                chips
                small-chips
                label="Dates, this meal should be searved on."
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-combobox>
            </template>
            <v-date-picker
              v-model="meal.dates"
              multiple
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(meal.dates)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
      </div>

      <div class="row">
        <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <v-textarea
            name="Description"
            label="Description"
            :rules="[max500Chars]"
            v-model="meal.description"
            rows="2"
            :disabled="disabledArr.includes('description')"
          ></v-textarea>
        </div>
      </div>

      <div class="row">
        <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <v-progress-circular
            v-if="posting"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-btn
            v-else
            depressed
            color="success"
            @click="save"
          >
            Save
          </v-btn>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import CommonsService from '@/services/commonsService.js'
import MealService from '@/services/mealservice'

import { mealRequest } from '@/app/Universal/Requests/meal'

export default {
  props: {
    disabledArr: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    commonsService: new CommonsService(),
    mealService: new MealService(),
    meal: {},
    items: ['Breakfast', 'Lunch', 'Dinner'],
    errorMessages: [],
    ingredient: '',
    ingredients: [],
    images: [],
    nutritionalImage: null,
    menu: false,
    posting: false
  }),
  methods: {
    // Rules
    required: value => {
      return !!value || 'Required'
    },
    min3Chars: value => {
      return (value && value.length >= 3) || 'Min 3 characters'
    },
    num: value => {
      return /^\d+$/.test(value) || 'Only digits are allowed'
    },
    min100Chars: value => {
      return !(value && value.length < 100) || 'At least 100 characters'
    },
    max500Chars: value => {
      return !(value && value.length >= 500) || 'Max 500 characters'
    },

    // Methods
    onFileSelect1(file) {
      if (file){
        if (file.size < 40000000) {
          this.nutritionalImage = file
        } else {
          this.writeMessage({
            type: "warning",
            name: "Warning",
            message: `File ${file.name} should be less than 5MB.`
          })
        }
      } else {
        this.writeMessage({
          type: "warning",
          name: "Warning",
          message: 'Please select a file.'
        })
      }
    },
    getFileName(name) {
      if (name && name.length > 40) {
        return `${name.substring(0, 20)}...${name.substring(name.length - 15, name.length-1)}`
      } else {
        return name
      }
    },
    onFileSelect(files) {
      // console.log(e)
      if (files && files[0]){
        for(let i = 0; i < files.length; i++) {

          let file = files[i]
          if (file.size < 40000000) {
            this.images.push(file)
          } else {
            this.writeMessage({
              type: "warning",
              name: "Warning",
              message: `File ${file.name} should be less than 5MB.`
            })
          }
        }

      } else {
        this.writeMessage({
          type: "warning",
          name: "Warning",
          message: 'Please select a file.'
        })
      }
    },
    addIngredient(val) {
      if (this.ingredient) {
        if (!this.ingredients.includes(this.ingredient)) {
          this.ingredients.push(this.ingredient)
          this.ingredient = ''
        } else {
          this.writeMessage({
            type: "warning",
            name: "Warning",
            message: 'Ingredient already in the list.'
          })
        }
      } else {
        this.writeMessage({
          type: "warning",
          name: "Warning",
          message: 'Please write an ingredient name.'
        })
      }
    },
    save() {
      if (this.meal) {
        let errorsCount = 0
        
        if (!this.meal.mealName) {
          this.writeMessage({
            type: "warning",
            name: "Warning",
            message: 'Meal name is required.'
          }, 4000)
          errorsCount++
        }
        
        if (this.ingredients && this.ingredients[0]) {
          this.meal.ingredients = this.ingredients
        } else {
          this.writeMessage({
            type: "warning",
            name: "Warning",
            message: 'Ingredients are required.'
          }, 4000)
          errorsCount++
        }
        
        if (!this.meal.mealType) {
          this.writeMessage({
            type: "warning",
            name: "Warning",
            message: 'Meal type is required.'
          }, 4000)
          errorsCount++
        }
        
        if (!this.nutritionalImage) {
          this.writeMessage({
            type: "warning",
            name: "Warning",
            message: 'Nutritional Facts Image is required.'
          }, 4000)
          errorsCount++
        }
        
        if (!(this.meal.dates && this.meal.dates[0])) {
          this.writeMessage({
            type: "warning",
            name: "Warning",
            message: 'At least 1 date is required.'
          }, 4000)
          errorsCount++
        }

        if (!this.meal.caloriesHigh) {
          this.writeMessage({
            type: "warning",
            name: "Warning",
            message: 'Calories High is required.'
          }, 4000)
          errorsCount++
        } else {
          if (!/^\d+$/.test(this.meal.caloriesHigh)) {
            this.writeMessage({
              type: "warning",
              name: "Warning",
              message: 'Calories High can only be in digits.'
            }, 4000)
            errorsCount++
          }
        }

        if (!this.meal.caloriesLow) {
          this.writeMessage({
            type: "warning",
            name: "Warning",
            message: 'Calories Low is required.'
          }, 4000)
          errorsCount++
        } else {
          if (!/^\d+$/.test(this.meal.caloriesLow)) {
            this.writeMessage({
              type: "warning",
              name: "Warning",
              message: 'Calories Low can only be in digits.'
            }, 4000)
            errorsCount++
          }
        }

        if (!errorsCount) {
          console.log('Send request', this.meal)
          this.posting = true
          mealRequest(this, 'MealForm.vue')
        } else {
            // window.location.hash = '#errors'
          let a = document.getElementById('errors')
          console.log(a)
          if (a) {
            setTimeout(() => a.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"}), 50)
          }
        }

      } else {
        this.writeMessage({
          type: "warning",
          name: "Warning",
          message: 'Please fill the form.',
          border: 'top'
        }, 4000)
      }
    },
    writeMessage(msg, time) {
      if (!msg.border) {
        msg.border = 'left'
      }

      this.errorMessages.push(msg)
      if (time) {
        setTimeout(() => {
          let index = this.errorMessages.findIndex(a => a.border === msg.border && a.type === msg.type && a.name === msg.name && a.message === msg.message)
          this.errorMessages.splice(index, 1)
        }, time)
      } else {  
        setTimeout(() => {
          let index = this.errorMessages.findIndex(a => a.border === msg.border && a.type === msg.type && a.name === msg.name && a.message === msg.message)
          this.errorMessages.splice(index, 1)
        }, 2000)
      }
    },
  }
}
</script>

<style>

</style>