<template>
  <div>
    <div class="row" style="margin: 0px 12px;">
      <div class="col col-lg-12 col-sm-12" style="padding: 3px 12px;">
        <span style="font-size: 22px;">CHECKOUT</span>
      </div>
    </div>
    <div class="row" style="margin: 0px 12px;">
      <div
        class="col col-lg-2 col-md-12 col-sm-12 col-xs-12"
        style="padding: 3px 12px;"
      ></div>
      <div
        class="col col-lg-8 col-md-12 col-sm-12 col-xs-12"
        style="padding: 3px 12px;"
      >
        <div class="row">
          <div class="col col-lg-12">
            <span style="float:left">DIET SUMMARY</span>
            <span style="float:right">PRICE</span>
          </div>
        </div>
      </div>
      <div
        class="col col-lg-2 col-md-12 col-sm-12 col-xs-12"
        style="padding: 3px 12px;"
      ></div>
    </div>
    <div class="row" style="margin: 0px 12px;">
      <div
        class="col col-lg-2 col-md-12 col-sm-12 col-xs-12"
        style="padding: 3px 12px;"
      ></div>
      <div
        class="col col-lg-8 col-md-12 col-sm-12 col-xs-12"
        style="padding: 3px 12px;"
      >
        <div class="row">
          <div class="col col-lg-12">
            <div style="float:left">
              <span
                style="float: left; font-size: 25px; font-weight: bold; color: #F26722;"
                >{{ order.dietType }}</span
              >
              <br />
              <span style="float: left; font-size: 16px; color: #818181;">{{
                mealsToShow
              }}</span>
              <br />
              <span style="float: left; font-size: 16px; color: #818181;"
                >{{ order.mealDays }} meals total</span
              >
            </div>
            <div style="float:right">
              <br />
              <br />
              <span style="float: right; font-size: 16px; font-weight: bold;"
                >{{ pricePerDay * order.mealDays }} EGP</span
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="col col-lg-2 col-md-12 col-sm-12 col-xs-12"
        style="padding: 3px 12px;"
      ></div>
    </div>
    <div class="row" style="margin: 0px 12px;">
      <div
        class="col col-lg-2 col-md-12 col-sm-12 col-xs-12"
        style="padding: 3px 12px;"
      ></div>
      <div
        class="col col-lg-8 col-md-12 col-sm-12 col-xs-12"
        style="padding: 3px 12px;"
      >
        <br />
        <hr />
        <br />
        <div class="row">
          <div class="col col-lg-12">
            <span style="float:left">PAYMENT METHOD</span>
            <br />
            <span style="float: left; font-size: 16px; color: #818181;"
              >Your available cards</span
            >
            <span style="float: right; font-size: 16px; color: #F26722;"
              >+ Link a new card</span
            >
          </div>
        </div>
      </div>
      <div
        class="col col-lg-2 col-md-12 col-sm-12 col-xs-12"
        style="padding: 3px 12px;"
      ></div>
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <div class="row" style="margin: 0px 12px;">
      <div class="col col-lg-12 col-sm-12" style="padding: 3px 12px;">
        <button class="p1b1" @click="$emit('gotoCheckout')">
          Pay Now
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    console.log(this.order);
    if (this.order.mealTimes && this.order.mealTimes[0]) {
      this.order.mealTimes.forEach(
        (a) =>
          (this.mealsToShow = this.mealsToShow.concat(a.toUpperCase() + ", "))
      );
      this.mealsToShow = this.mealsToShow.substring(
        0,
        this.mealsToShow.length - 2
      );
    }
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    pricePerDay: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    mealsToShow: "",
  }),
};
</script>

<style></style>
