<template>
  <div>
    <section class="page1">
      <v-container>
        <!-- <Tabs :active="active" @setActive="setActive"/> -->
        <Tabs v-if="active < 6" :active="active" @setActive="setActive" />
        <div v-else>
          <br />
          <br />
        </div>

        <div class="row">
          <div class="col col-lg-12">
            <div class="tab" id="tabWindow">
              <ChooseTheDiet
                v-if="active === 1"
                :waiting="requesting"
                @chooseDietType="chooseDietType"
              />
              <TargetCalories
                v-if="active === 2"
                :dietType="order.dietType"
                :targetCalories="order.targetCalories"
                @resetDietType="resetDietType"
                @setTargetCalories="setTargetCalories"
              />
              <ChooseMeals
                v-if="active === 3"
                :dietType="order.dietType"
                :mealTimes="order.mealTimes"
                :mealDays="order.mealDays"
                :pricePerDay="pricePerDay"
                @setMeals="setMeals"
                @resetDietType="resetDietType"
              />
              <AdditionalSnacks
                v-if="active === 4"
                :dietType="order.dietType"
                :snacks="order.snacks"
                @skip="skipSnacks"
                @addSnacks="addSnacks"
                @resetDietType="resetDietType"
              />
              <Checkout
                v-if="active === 5"
                :orderInFirestore="orderInFirestore"
                :responseMessage="responseMessage"
                @checkOutDone="checkOutDone"
              />
              <SetupCalendar
                v-if="active === 6"
                @selectedDate="mealStartDate"
              />
              <MealSelector
                v-if="active === 7"
                @mealSelection="mealSelection"
              />
              <SetupCalendarDetails
                v-if="active === 8"
                :calories="calories[order.targetCalories]"
                :mealTimes="order.mealTimes"
                :mealDays="order.mealDays"
                :startDate="order.startDate"
                @backToCalendar="backToCalendar"
                @done="mealsSelectionDone"
              />
              <ThankYouForOrdering
                v-if="active === 9"
                @selectedOption="selectedOption"
              />
            </div>
          </div>
        </div>
      </v-container>
    </section>
  </div>
</template>

<script>
import OrderService from "@/services/orderService";

import Tabs from "./OrderNow/Tabs";
import ChooseTheDiet from "./OrderNow/ChooseTheDiet";
import TargetCalories from "./OrderNow/TargetCalories";
import ChooseMeals from "./OrderNow/ChooseMeals";
import AdditionalSnacks from "./OrderNow/AdditionalSnacks";
import Checkout from "./OrderNow/Checkout";
import SetupCalendar from "./OrderNow/SetupCalendar";
import MealSelector from "./OrderNow/MealSelector";
import SetupCalendarDetails from "./OrderNow/SetupCalendarDetails";
import ThankYouForOrdering from "./OrderNow/ThankYouForOrdering";

import { ordersCollection } from "@/config/firebase";

export default {
  components: {
    Tabs,
    ChooseTheDiet,
    TargetCalories,
    ChooseMeals,
    AdditionalSnacks,
    Checkout,
    SetupCalendar,
    MealSelector,
    SetupCalendarDetails,
    ThankYouForOrdering,
  },
  created() {
    this.adjustTabsContentWindowHeightForLG();

    if (this.$route.query && this.$route.query.id) {
      this.orderResponse = this.$route.query;
      console.log(this.$route.query.order);
      this.getOrderByOrderId(this.$route.query.order);

      // if (this.$route.query.success) {
      //   this.checkOutDone()
      // }
      this.active = 5;
    }
  },
  data: () => ({
    orderResponse: {},
    responseMessage: "",
    orderService: new OrderService(),
    active: 1,
    order: {
      dietType: "",
      targetCalories: "",
      calories: {},
      mealTimes: [],
      mealDays: 0,
      snacks: [],
      startDate: null,
      mealsPerDay: [],
      orderStatus: "Unpaid",
    },
    orderedKey: "",
    orderInFirestore: {},
    mealSelectionType: null,
    calories: [
      { low: 1200, high: 1500 },
      { low: 1500, high: 2000 },
    ],
    pricePerDay: 200,
    checkOut: false,
    requesting: false,
  }),
  watch: {
    orderInFirestore(val) {
      console.log("Current order", val);
    },
    active(val) {
      // console.log(val)
      if (
        val === 5 &&
        this.order.mealTimes && this.order.mealTimes[0] &&
        this.order.mealDays &&
        !this.orderedKey
      ) {
        let order = { ...this.order };
        delete order.targetCalories;
        this.orderService
          .saveOrder(order)
          .then((data) => {
            console.log(data);
            this.orderedKey = data;

            // listen for order for payment token
            ordersCollection.doc(data).onSnapshot((doc) => {
              this.orderInFirestore = doc.data();
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }

      if (val === 2 && !this.order.dietType) {
        this.active = 1;
      } else if (val === 3 && !this.order.targetCalories) {
        this.active = 2;
      } else if (
        val === 4 &&
        !(this.order.mealTimes && this.order.mealTimes[0]) &&
        !this.order.mealDays
      ) {
        this.active = 3;
      } else if (
        val === 5 &&
        !(this.order.mealTimes && this.order.mealTimes[0]) &&
        !this.order.mealDays
      ) {
        this.active = 4;
      } else if (val === 6 && !this.checkOut) {
        this.active = 5;
      } else if (val === 7 && !this.order.startDate) {
        this.active = 6;
      } else if (val === 8 && !this.mealSelectionType) {
        this.active = 7;
      } else if (val === 9 && !this.mealSelectionType) {
        this.active = 7;
      } else {
        this.removeTicks(val);
      }

      this.adjustTabsContentWindowHeightForLG();
    },
  },
  methods: {
    selectedOption(option) {
      console.log(this.order);
      if (option === 1) {
        this.$router.push("/");
      }
    },
    mealsSelectionDone(meals) {
      // console.log(meals)
      let mealsPerDay = [];
      meals.forEach((meal) => {
        let obj = {};
        obj.date = meal.date;
        this.order.mealTimes.forEach((time) => {
          if (meal[`${time}Meal`]) {
            obj[`${time}`] = meal[`${time}Meal`].key;
          }
        });
        mealsPerDay.push(obj);
      });
      // console.log(mealsPerDay)
      this.order.mealsPerDay = mealsPerDay;
      this.active = 9;

      let order = { ...this.order, orderStatus: "Completed" };
      this.updateOrder(order);
    },
    updateOrder(order) {
      if (this.orderedKey && order) {
        delete order.dietType; // already posted
        delete order.targetCalories;
        delete order.mealTimes; // already posted
        delete order.mealDays; // already posted
        this.orderService
          .updateOrder(this.orderedKey, order)
          .then(() => {
            console.log("Order updated");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    adjustTabsContentWindowHeightForLG() {
      let a = document.getElementById("tabWindow");
      if (a) {
        if (this.active >= 6 && this.checkOut) {
          a.style.height = "600px";
          // a.style.height = '90%'
        } else {
          a.style.height = "500px";
          // a.style.height = '115%'
        }
      }
    },
    removeTicks(num) {
      console.log(num);
      for (let i = 1; i < num; i++) {
        let a = document.getElementById("tab" + i);
        if (a) {
          a.style.border = "2px solid #5ecbf0";
          a.style.backgroundColor = "white";
        }
        let b = document.getElementById("after" + i);
        // console.log(i, a, b)
        if (b) {
          // console.log(b.children[0])
          b.children[0].style.border = "2px solid #5ecbf0";
          b.children[0].style.backgroundColor = "#5ecbf0";
        }
        let c = document.getElementById("tabImg" + i);
        if (c) {
          c.style.display = "block";
        }
      }

      let a = document.getElementById("tab" + num);
      // console.log(a)
      if (a) {
        a.style.border = "2px solid #F26722";
        a.style.backgroundColor = "#F26722";
      }
      let b = document.getElementById("tabImg" + num);
      if (b) {
        b.style.display = "none";
      }

      for (let i = num + 1; i <= 6; i++) {
        let a = document.getElementById("tab" + i);
        // console.log(a)
        if (a) {
          a.style.border = "2px solid white";
          a.style.backgroundColor = "white";
        }
        let b = document.getElementById("tabImg" + i);
        if (b) {
          b.style.display = "none";
        }
      }
      for (let i = num; i <= 6; i++) {
        let b = document.getElementById("after" + i);
        if (b) {
          // console.log(b.children[0])
          b.children[0].style.border = "2px solid #D9E6FF";
          b.children[0].style.backgroundColor = "#D9E6FF";
        }
      }
    },
    checkOutDone() {
      console.log("DOne");

      let order = { ...this.order, orderStatus: "Paid and Processing" };
      this.updateOrder(order);

      this.checkOut = true;
      this.active = 6;
    },
    addSnacks(snacks) {
      this.order.snacks = snacks;
      this.active = 5;
    },
    skipSnacks() {
      this.order.snacks = [];
      this.active = 5;
    },
    setMeals({ mealTimes, mealDays }) {
      console.log(mealTimes, mealDays);
      if (mealTimes && mealTimes[0] && mealDays) {
        this.order.mealTimes = mealTimes;
        this.order.mealDays = parseInt(mealDays);
        this.active = 4;
      }
    },
    setTargetCalories(value) {
      console.log(value);
      if (value) {
        this.order.targetCalories = value;
        this.order.calories = this.calories[value];
        this.active = 3;
      }
    },
    resetDietType() {
      this.order.dietType = "";
      this.active = 1;
    },
    chooseDietType(value) {
      console.log(value);
      if (value) {
        this.order.dietType = value;
        this.active++;
      }
    },
    setActive(val) {
      this.active = val;
    },
    mealStartDate(date) {
      if (date) {
        this.order.startDate = new Date(date);
        // console.log(date, this.startDate)
        this.active = 7;

        let order = {
          ...this.order,
          orderStatus: "Paid and Building Calendar",
        };
        this.updateOrder(order);
      }
    },
    mealSelection(type) {
      this.mealSelectionType = type;
      if (type === 1) {
        this.active = 8;
      } else if (type === 2) {
        this.active = 9;
      }
    },
    backToCalendar() {
      this.order.startDate = null;
      this.mealSelectionType = null;
      this.active = 7;
    },
    getOrderByOrderId(orderId) {
      this.order = {};
      this.requesting = true;

      this.orderService
        .getOrderByOrderId(parseInt(orderId))
        .then((data) => {
          console.log(data);
          this.requesting = false;
          this.order = data;
          this.orderInFirestore = data;
          this.active = 5;
          if (data.key) {
            this.orderedKey = data.key;
          }
          if (data.calories && data.calories.low && data.calories.high) {
            this.order.targetCalories = this.calories.findIndex(
              (a) =>
                a.low === data.calories.low && a.high === data.calories.high
            );
          }
          if (this.orderInFirestore.orderStatus !== "Paid") {
            this.responseMessage = "Filed Transaction";
            setTimeout(() => {
              this.responseMessage = "";
            }, 2000);
          }
        })
        .catch((error) => {
          this.requesting = false;
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
