<template>
  <div class="pt-5 fullHeight" id="mainCard">
    <div class="row">
      <div class="col col-lg-12 col-sm-12">
        <span style="font-size: 22px;">SETUP YOUR MEALS CALENDAR</span>
      </div>
    </div>
    <br />

    <div id="backToCalendar" @click="$emit('backToCalendar')">
      <img
        style="vertical-align:middle;"
        src="@/assets/images/arrow-ic-back-to-calendar.png"
        class="pl-2"
      />
      &nbsp;
      <span>Back to calendar</span>
    </div>

    <div class="row" style="margin: 0px 12px; color: black;">
      <div class="col col-lg-3 col-sm-12" style="padding: 3px 12px;"></div>
      <div class="col col-lg-6 col-sm-12" style="padding: 3px 12px;">
        <div class="row" style="text-align: center;">
          <div :class="'col col-lg-4' + prevClass()" style="padding: 3px 12px;">
            <img
              v-if="!prevClass()"
              @click="previousDate()"
              src="@/assets/images/arrow-ic-24.png"
              class="pl-2 prevNextBtn"
            />
            <img
              v-else
              @click="previousDate()"
              src="@/assets/images/arrow-ic-blur-back.png"
              class="pl-2 prevNextBtn"
            />
            <span>Previous Day</span>
          </div>
          <div class="col col-lg-4" style="padding: 3px 12px; font-size: 18px;">
            <span
              >{{ days[dayInView] }} {{ dateInView }}, {{ months[monthInView] }}
            </span>
          </div>
          <div :class="'col col-lg-4' + nextClass()" style="padding: 3px 12px;">
            <span>Next Day</span>
            <img
              @click="nextDate()"
              src="@/assets/images/arrow-ic-25.png"
              class="pl-2 prevNextBtn"
            />
          </div>
        </div>
      </div>
      <div class="col col-lg-3 col-sm-12" style="padding: 3px 12px;"></div>
    </div>

    <div class="row" style="margin: 0px 12px; color: black;">
      <div class="col col-lg-12 col-sm-12" style="padding: 3px 12px;">
        <span @click="$emit('backToCalendar')" class="changeStartDate"
          >Change Start Date</span
        >
      </div>
    </div>

    <div class="row" style="margin: 0px 12px;">
      <div class="col col-lg-12 col-sm-12" style="padding: 3px 12px;">
        <v-tabs color="black" centered v-model="tab">
          <v-tabs-slider color="#F26722"></v-tabs-slider>
          <v-tab v-if="mealTimes[0]">{{ mealTimes[0].toUpperCase() }}</v-tab>
          <v-tab v-if="mealTimes[1]">{{ mealTimes[1].toUpperCase() }}</v-tab>
          <v-tab v-if="mealTimes[2]">{{ mealTimes[2].toUpperCase() }}</v-tab>
        </v-tabs>
      </div>
    </div>

    <div class="row" style="margin: 0px 12px;">
      <div class="col col-lg-12 col-sm-12" style="padding: 3px 12px;">
        <span>Choose one of {{ mealTimes[tab] }} meals for this day</span>
      </div>
    </div>

    <div
      v-if="gettingMeals"
      class="row"
      style="margin: 0px 12px; height: 200px;"
    >
      <div
        class="col col-lg-12 col-md-12 col-sm-12 col-xs-12"
        style="padding: 3px 12px;"
      >
        <v-progress-circular
          indeterminate
          color="#F26722"
        ></v-progress-circular>
      </div>
    </div>
    <div
      v-else-if="tabMeals && meals[0]"
      class="row"
      :key="reloadCards"
      style="margin: 0px 12px; height: 220px;"
    >
      <div
        class="col col-lg-1 col-md-12 col-sm-12 col-xs-12"
        style="padding: 3px 12px;"
      ></div>
      <div
        class="col col-lg-5 col-md-6 col-sm-12 col-xs-12"
        style="padding: 3px 12px;"
      >
        <div
          v-if="
            tabMeals[0] && tabMeals[0].mealType === this.mealTimes[this.tab]
          "
          :class="'card ' + getBorderClass(1)"
          @click="mealSelected(1, $event)"
        >
          <div class="aboveDiv">
            <div class="topRightCorner">
              <img src="@/assets/images/veg-ic.png" />
              <span>Vegetarian</span>
            </div>
            <span>{{ tabMeals[0].mealName.toUpperCase() }}</span>
            <p>
              {{
                tabMeals[0].description.length > 250
                  ? tabMeals[0].description.slice(0, 250)
                  : tabMeals[0].description
              }}
            </p>
          </div>
          <div class="belowDiv">
            <div class="left">
              <img
                style="max-height: 100%; max-width: 100%;"
                :src="
                  tabMeals[0].mealImages.length
                    ? tabMeals[0].mealImages[0].image
                    : tabMeals[0].nutritionalImage
                "
              />
            </div>
            <div class="right" style="padding: 0px;">
              <div class="row" style="margin: 0px; font-size: 13px;">
                <div
                  class="col col-lg-6"
                  style="padding: 3px 12px; float: left;"
                >
                  <span>Nutrition Facts</span>
                </div>
                <div
                  class="col col-lg-6 nutritionalDetails"
                  style="padding: 3px 12px; float: right; color: #F26722;"
                >
                  <span class="nutritionalDetails">Nutrition Details</span>
                  <img
                    class="mealImgDeatils"
                    src="@/assets/images/arrow-ic-1.png"
                  />
                </div>
              </div>
              <div class="row" style="margin: 0px; font-size: 13px;">
                <div
                  class="col col-lg-12"
                  style="padding: 3px 12px; float: left;"
                >
                  <strong>{{ getCaloriesLabel() }} </strong>
                  {{ getCaloriesValue(0) }}
                  <!-- <img
                    class="mealImgDeatils"
                    src="@/assets/images/NFact-4.png"
                  >
                  <img
                    class="mealImgDeatils"
                    src="@/assets/images/NFact-3.png"
                  >
                  <img
                    class="mealImgDeatils"
                    src="@/assets/images/NFact-2.png"
                  >
                  <img
                    class="mealImgDeatils"
                    src="@/assets/images/NFact-1.png"
                  > -->
                  <!-- <img
                    class="mealImgDeatils"
                    src="@/assets/images/NFact.png"
                  > -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col col-lg-5 col-md-6 col-sm-12 col-xs-12"
        style="padding: 3px 12px;"
      >
        <div
          v-if="
            tabMeals[1] && tabMeals[1].mealType === this.mealTimes[this.tab]
          "
          :class="'card ' + getBorderClass(2)"
          @click="mealSelected(2, $event)"
        >
          <div class="aboveDiv">
            <div class="topRightCorner">
              <img src="@/assets/images/veg-ic.png" />
              <span>Vegetarian</span>
            </div>
            <span>{{ tabMeals[1].mealName.toUpperCase() }}</span>
            <p>
              {{
                tabMeals[1].description.length > 250
                  ? tabMeals[1].description.slice(0, 250)
                  : tabMeals[1].description
              }}
            </p>
          </div>
          <div class="belowDiv">
            <div class="left">
              <img
                style="max-height: 100%; max-width: 100%;"
                :src="
                  tabMeals[0].mealImages.length
                    ? tabMeals[0].mealImages[0].image
                    : tabMeals[0].nutritionalImage
                "
              />
            </div>
            <div class="right" style="padding: 0px;">
              <div class="row" style="margin: 0px; font-size: 13px;">
                <div
                  class="col col-lg-6"
                  style="padding: 3px 12px; float: left;"
                >
                  <span>Nutrition Facts</span>
                </div>
                <div
                  class="col col-lg-6 nutritionalDetails"
                  style="padding: 3px 12px; float: right; color: #F26722;"
                >
                  <span class="nutritionalDetails">Nutrition Details</span>
                  <img
                    class="mealImgDeatils"
                    src="@/assets/images/arrow-ic-1.png"
                  />
                </div>
              </div>
              <div class="row" style="margin: 0px; font-size: 13px;">
                <div
                  class="col col-lg-12"
                  style="padding: 3px 12px; float: left;"
                >
                  <strong>{{ getCaloriesLabel() }} </strong>
                  {{ getCaloriesValue(1) }}
                  <!-- <img
                    class="mealImgDeatils"
                    src="@/assets/images/NFact-4.png"
                  >
                  <img
                    class="mealImgDeatils"
                    src="@/assets/images/NFact-3.png"
                  >
                  <img
                    class="mealImgDeatils"
                    src="@/assets/images/NFact-2.png"
                  >
                  <img
                    class="mealImgDeatils"
                    src="@/assets/images/NFact-1.png"
                  > -->
                  <!-- <img
                    class="mealImgDeatils"
                    src="@/assets/images/NFact.png"
                  > -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col col-lg-1 col-md-12 col-sm-12 col-xs-12"
        style="padding: 3px 12px;"
      ></div>
    </div>
    <div v-else class="row" style="margin: 0px 12px; height: 200px;">
      <div
        class="col col-lg-12 col-md-12 col-sm-12 col-xs-12"
        style="padding: 3px 12px;"
      >
        <span>No meal found.</span>
      </div>
    </div>

    <br />
    <br />
    <br />
    <br />
    <br />
    <div class="row" style="margin: 0px 12px; color: black;">
      <div class="col col-lg-12 col-sm-12" style="padding: 3px 12px;">
        <div class="skipDay">
          <span @click="skipDay()">Skip Day</span>
          <br />
          <span>( {{ 3 - skipArray.length }} Remaining skips )</span>
        </div>
      </div>
    </div>

    <span class="selectedTab">{{ mealTimes[tab] }}</span>
    <br />
    <span class="planDetails"
      >{{ this.mealDays - this.mealInDays.length }} of {{ this.mealDays }} Plan
      Days Remaining</span
    >

    <div class="text-center">
      <v-dialog v-model="nutritionalDialog" width="500">
        <v-card>
          <v-img
            :src="nutritionalUrl"
            :lazy-src="nutritionalUrl"
            min-height="100px"
            class="grey lighten-2"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="nutritionalDialog = false">
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mealsCollection } from "@/config/firebase.js";

export default {
  created() {
    console.log(this.mealTimes, this.mealDays, this.startDate);
    this.setupMealDates();
  },
  mounted() {
    this.backButtonPlacement();
  },
  props: {
    calories: {
      type: Object,
      required: false,
    },
    mealTimes: {
      type: Array,
      required: true,
    },
    mealDays: {
      type: Number,
      required: true,
    },
    startDate: {
      type: Date,
      required: true,
    },
  },
  data: () => ({
    days: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    dates: [],
    fullDateInView: null,
    dayInView: null,
    dateInView: null,
    monthInView: null,
    tab: 0,
    mealDescription:
      "Aliquam Faucibus, Odio Nec Commodo Aliquam, Neque Felis Placerat Dui, A Porta Ante Lectus Dapibus Est. Aliquam A Bibendum Mi, Sed Condimentum Est. Mauris Arcu Odio, Vestibulum Quis Dapibus Sit Amet, Finibus Id Turpis. Aliquam Semper Fringilla Semper. Sed Nec Velit Sit Amet Dolor Pulvinar Feugiat. Suspendisse Blandit, Nulla Sed Interdum Egestas, Nibh Ex Maximus Arcu, Non Posuere Sem Nulla In Augue. Class Aptent Taciti Sociosqu Ad Litora Torquent Per Conubia Nostra, Per Inceptos Himenaeos. Maecenas Mattis Sapien Vel Sollicitudin Blandit. Donec Nec Porttitor Eros. Praesent Blandit, Erat Non Vehicula Rutrum, Mauris Orci Scelerisque Urna, Rutrum Malesuada Odio Magna At Felis. Fusce Convallis Elit In Risus Tincidunt Ullamcorper. Aliquam Maximus Dolor Turpis, Nec Commodo Libero Mattis Ut.",
    mealInDays: [],
    reloadCards: 0,
    skipArray: [],
    meals: [],
    tabMeals: [],
    gettingMeals: false,
    nutritionalDialog: false,
    nutritionalUrl: "",
  }),
  computed: {
    windowWidth() {
      return this.$store.getters.getAppWindowWidth;
    },
    windowHeight() {
      return this.$store.getters.getAppWindowHeight;
    },
  },
  watch: {
    tab(val) {
      console.log(val, this.oldTab, this.mealTimes[val]);

      this.tabMeals = this.meals.filter(
        (meal) => meal.mealType === this.mealTimes[val]
      );
      // console.log(this.tabMeals)
    },
    async fullDateInView(val) {
      let date = new Date(val);
      if (date) {
        this.dayInView = date.getDay();
        this.dateInView = date.getDate();
        this.monthInView = date.getMonth();

        this.meals = [];

        let year = date.getFullYear(),
          d = new Date(year, this.monthInView, this.dateInView);

        this.gettingMeals = true;
        const snapshot = await mealsCollection
          // .where('caloriesLow', '>', this.calories.low)
          // .where('caloriesLow', '<', this.calories.high)
          .where("dates", "array-contains", d)
          .get();
        this.gettingMeals = false;

        if (snapshot.empty) {
          console.log("No matching documents.");
          return;
        }

        snapshot.forEach((doc) => {
          let meal = { ...doc.data(), key: doc.id };
          // if (meal.caloriesHigh <= this.calories.high) {
          this.meals.push(meal);
          this.tabMeals = this.meals.filter(
            (meal) => meal.mealType === this.mealTimes[this.tab]
          );
          // }

          console.log(doc.id, "=>", doc.data(), this.meals);
        });
      } else {
        this.dayInView = null;
        this.dateInView = null;
        this.monthInView = null;
      }
    },
    windowHeight(val) {
      this.backButtonPlacement();
    },
  },
  methods: {
    getCaloriesLabel() {
      if (`${this.calories.low}~${this.calories.high}` === "1200~1500") {
        return "Calories Low";
      } else if (`${this.calories.low}~${this.calories.high}` === "1500~2000") {
        return "Calories High";
      } else {
        return "Calories";
      }
    },
    getCaloriesValue(number) {
      if (`${this.calories.low}~${this.calories.high}` === "1200~1500") {
        return this.tabMeals[number].caloriesLow;
      } else if (`${this.calories.low}~${this.calories.high}` === "1500~2000") {
        return this.tabMeals[number].caloriesHigh;
      } else {
        return `${this.calories.low}~${this.calories.high}`;
      }
    },
    skipDay() {
      // console.log(this.mealInDays, this.fullDateInView, this.dates, this.skipArray)

      if (this.skipArray.length < 3) {
        this.skipArray.push(this.fullDateInView);

        this.makeDates(this.skipArray)
          .then((dates) => {
            console.log(dates);

            this.dates = dates;
            var mealInDays = this.mealInDays;
            this.mealInDays = [];
            var index = 0;
            mealInDays.forEach((meal) => {
              if (meal && meal.date && dates.includes(meal.date)) {
                this.mealInDays.push(meal);
                index++;
              }
            });
            console.log(index, this.mealInDays);
            this.fullDateInView = dates[index];
          })
          .catch((e) => console.log(e));
      } else {
        console.log("Can not skip more than 3 days.");
      }
    },
    getBorderClass(meal) {
      let index = this.mealInDays.findIndex(
        (a) => a.date === this.fullDateInView
      );

      if (!(index === null || index === undefined)) {
        // console.log(index, this.mealInDays[index], this.mealTimes[this.tab])
        if (this.mealInDays[index]) {
          // console.log(this.mealInDays[index][this.mealTimes[this.tab]])

          if (
            this.mealInDays[index][this.mealTimes[this.tab]] &&
            this.mealInDays[index][this.mealTimes[this.tab]] === meal
          ) {
            return "yellowBorder";
          } else {
            return "";
          }
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    mealSelected(meal, event) {
      // console.log(meal, this.dates, this.fullDateInView, this.tab, this.mealTimes, this.tabMeals)

      // console.log(event.target.className)

      if (!event.target.className.includes("nutritionalDetails")) {
        let index = this.dates.findIndex((a) => a === this.fullDateInView);
        if (!(this.mealInDays[index] && this.mealInDays[index].date)) {
          this.mealInDays[index] = {
            date: this.fullDateInView,
          };
        }
        this.mealInDays[index][this.mealTimes[this.tab]] = meal;
        this.mealInDays[index][
          `${this.mealTimes[this.tab]}Meal`
        ] = this.tabMeals[meal - 1];
        // console.log(this.mealInDays)

        this.nextDate();
        this.reloadCards++;
      } else {
        this.nutritionalUrl = this.tabMeals[meal - 1].nutritionalImage;
        this.nutritionalDialog = true;
      }
    },
    prevClass() {
      let index = this.dates.findIndex((a) => a === this.fullDateInView);
      return index > 0 ? "" : " blurred";
    },
    nextClass() {
      let index = this.dates.findIndex((a) => a === this.fullDateInView);
      return index < this.dates.length - 1 ? "" : " blurred";
    },
    previousDate() {
      let index = this.dates.findIndex((a) => a === this.fullDateInView);
      if (index > 0) {
        this.fullDateInView = this.dates[index - 1];
      }
    },
    nextDate() {
      let a = this.mealInDays.findIndex((a) => a.date === this.fullDateInView);
      // console.log(this.mealInDays[a])
      if (this.mealInDays[a]) {
        var includes = false;
        for (let i = 0; i < this.mealTimes.length; i++) {
          // console.log(this.mealTimes[i], this.mealInDays[a][this.mealTimes[i]])

          if (
            this.mealInDays[a][this.mealTimes[i]] === null ||
            this.mealInDays[a][this.mealTimes[i]] === undefined
          ) {
            includes = false;
          } else {
            includes = true;
          }
          // console.log(includes)

          if (!includes) {
            console.log("Plese select meal for " + this.mealTimes[i]);
            this.tab = i;
            break;
          }

          if (i === this.mealTimes.length - 1 && includes) {
            let index = this.dates.findIndex((a) => a === this.fullDateInView);

            if (index < this.dates.length - 1) {
              this.fullDateInView = this.dates[index + 1];
              this.tab = 0;
            } else {
              // console.log('Done', this.mealInDays, this.mealDays)
              if (this.mealInDays.length === this.mealDays)
                this.$emit("done", this.mealInDays);
            }
          }
        }
      } else {
        console.log("Plese select meals for " + this.fullDateInView);
      }
    },
    backButtonPlacement() {
      let a = document.getElementById("mainCard"),
        b = document.getElementById("backToCalendar");
      // console.log(a, b)
      if (a && b) {
        let c = a.getBoundingClientRect();
        // console.log(c)

        b.style.top = c.top + 20 + "px";
      }
    },
    setupMealDates() {
      console.log(this.startDate);

      this.makeDates([])
        .then((dates) => {
          // console.log(dates)

          this.dates = dates;
          this.fullDateInView = dates[0];
        })
        .catch((e) => console.log(e));
    },
    makeDates(skipArray) {
      return new Promise((resolve, reject) => {
        try {
          let day = this.startDate.getDate(),
            month = this.startDate.getMonth(),
            year = this.startDate.getFullYear(),
            notDone = true,
            dates = [];

          while (notDone) {
            let date = new Date(year, month, day),
              year1 = date.getFullYear(),
              month1 = date.getMonth(),
              day1 = date.getDate(),
              weekDay = date.getDay(),
              fullDate = `${year1}-${month1 + 1}-${day1}`;

            if (
              !(weekDay === 5 || weekDay === 6) &&
              !skipArray.includes(fullDate)
            ) {
              dates.push(fullDate);

              if (dates.length === this.mealDays) {
                notDone = false;
                resolve(dates);
              }
            }

            day++;
          }
        } catch (e) {
          reject(e);
        }
      });
    },
  },
};
</script>

<style scoped>
#backToCalendar {
  position: absolute;
  cursor: pointer;
  color: #818181;
}

.prevNextBtn {
  vertical-align: middle;
  padding: 0px 5px;
  cursor: pointer;
}

.blurred {
  color: #818181;
}

.changeStartDate {
  color: #f26722;
  font-size: 12px;
  cursor: pointer;
}

.card {
  width: 100%;
  height: 100%;
  background-color: #f7f9fc;
  border: 2px solid #f7f9fc;
  border-radius: 15px;
  cursor: pointer;
}

.yellowBorder {
  border: 2px solid #f26722;
}

.card:hover {
  border: 2px solid #f26722;
}

.aboveDiv {
  border-radius: inherit;
  height: 50%;
  background-color: #f7f9fc;
}

.belowDiv {
  width: 99%;
  border-radius: 0px 0px 10px 10px;
  height: 49%;
  background-color: #ffffff;
  margin: auto auto;
}

.topRightCorner {
  float: right;
  background-color: #339044;
  color: white;
  border-color: #339044;
  border-top-right-radius: inherit;
  border-bottom-left-radius: inherit;
  width: 120px;
  height: 30px;
  text-align: center;
}

.topRightCorner img {
  vertical-align: middle;
}

.topRightCorner p {
  height: inherit;
}

.aboveDiv > span {
  color: #818181;
  font-size: 17px;
  padding: 10px 0px 0px 20px;
  float: left;
}

.aboveDiv > p {
  color: black;
  font-size: 13px;
  float: left;
  padding: 5px 20px;
  text-align: justify;
  margin: 0px;
}

.left {
  float: left;
  width: 30%;
  height: 100%;
}

.right {
  float: right;
  width: 67%;
  height: 100%;
  padding-top: 25px;
}

.mealImgDeatils {
  padding: 0% 2%;
}

.selectedTab {
  position: absolute;
  left: 14%;
  bottom: 17%;
  font-size: 40px;
  font-weight: bold;
  color: #818181;
}

.planDetails {
  position: absolute;
  left: 14%;
  bottom: 15%;
  font-size: 15px;
  font-weight: bold;
  color: #818181;
}

.skipDay {
  font-size: 14px;
}

.skipDay > span:nth-of-type(1) {
  color: #f26722;
  cursor: pointer;
}

.skipDay > span:nth-of-type(2) {
  color: #818181;
}

/* .nutritionalDetails:hover {
  text-decoration: underline;
} */
</style>
