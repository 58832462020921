import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'

import ClientHomeHeader from './app/Client/Headers/HomeHeader.vue'
import ClientHomeFooter from './app/Client/Footers/HomeFooter.vue'

import ClientHome from './app/Client/Home/Home.vue'

import AdminHomeHeader from './app/Admin/Headers/HomeHeader.vue'
import AdminHomeFooter from './app/Admin/Footers/HomeFooter.vue'

import Admin from './app/Admin/admin.vue'
import Home from './app/Admin/Home/Home.vue'
import Meals from './app/Admin/Meals/Meals.vue'
import Meal from './app/Admin/Meal/Meal.vue'
import AddMeal from './app/Admin/MealForm/Meal.vue'
import Orders from './app/Admin/Orders/Orders.vue'
import Order from './app/Admin/Order/Order.vue'
import User from './app/Admin/User/User.vue'

import OrderNowAfterPayment from './app/Client/Home/Pages/OrderNowAfterPayment.vue'
import ClientOrders from './app/Client/Orders/Orders.vue'
import ClientProfile from './app/Client/Profile/Profile.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    components: {
      header: ClientHomeHeader,
      default: ClientHome,
      footer: ClientHomeFooter
    }
  },
  {
    path: '/orders',
    name: 'orders',
    components: {
      header: ClientHomeHeader,
      default: ClientOrders,
      footer: ClientHomeFooter
    },
    beforeEnter(to, from, next) {
      if (store.getters['auth/getProfile'] && (store.getters['auth/getProfile'].uid || store.getters['auth/getProfile'].key)) {
        next()
      } else {
        next({ name: '/' })
      }
    }
  },
  {
    path: '/profile',
    name: 'profile',
    components: {
      header: ClientHomeHeader,
      default: ClientProfile,
      footer: ClientHomeFooter
    },
    beforeEnter(to, from, next) {
      if (store.getters['auth/getProfile'] && (store.getters['auth/getProfile'].uid || store.getters['auth/getProfile'].key)) {
        next()
      } else {
        next({ name: '/' })
      }
    }
  },
  {
    path: '/order',
    name: 'order',
    component: OrderNowAfterPayment
  },
  {
    path: '/admin',
    name: 'admin',
    components: {
      header: AdminHomeHeader,
      default: Admin,
      footer: AdminHomeFooter
    },
    children: [
      {
        path: 'home',
        name: 'adminHome',
        component: Home
      },
      {
        path: 'meals',
        name: 'adminMeals',
        component: Meals
        // components: {
        //   header: HomeHeader,
        //   default: Meal,
        //   footer: HomeFooter
        // }
      },
      {
        path: 'meal/:id',
        name: 'adminMeal',
        component: Meal
        // components: {
        //   header: HomeHeader,
        //   default: Meal,
        //   footer: HomeFooter
        // }
      },
      {
        path: 'meal/add',
        name: 'adminAddMeal',
        component: AddMeal
      },
      {
        path: 'orders',
        name: 'adminOrders',
        component: Orders
      },
      {
        path: 'order/:id',
        name: 'adminOrder',
        component: Order
        // components: {
        //   header: HomeHeader,
        //   default: Meal,
        //   footer: HomeFooter
        // }
      },
      {
        path: 'user',
        name: 'adminUser',
        component: User
      }
    ]
  },
  {
    path: '/defaultHome',
    name: 'DefaultHome',
    components: {
      header: () => import('./views/Home/HomeHeader.vue'),
      default: () => import('./views/Home/Home.vue'),
      footer: () => import('./views/Home/HomeFooter.vue')
    }
  },
  {
    path: '/defaultAbout',
    name: 'DefaultAbout',
    components: {
      header: () => import(/* webpackChunkName: "about" */ './views/About/AboutHeader.vue'),
      default: () => import(/* webpackChunkName: "about" */ './views/About/About.vue'),
      footer: () => import(/* webpackChunkName: "about" */ './views/About/AboutFooter.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log(to.fullPath, from.fullPath, store.getters['auth/getProfile'])
  next()
})

export default router
