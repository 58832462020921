<template>
  <div class="pt-5 fullHeight">
    <div class="row">
      <div class="col col-lg-12 col-sm-12">
        <span style="font-size: 22px;">Setup Your Meal Calendar</span>
      </div>
    </div>
    <br />

    <div class="row" style="margin: 0px 12px;">
      <div
        class="col col-lg-4 col-md-3 parent"
        style="padding: 3px 12px;"
      ></div>
      <div class="col col-lg-4 col-md-6 parent" style="padding: 3px 12px;">
        <div v-if="days[day]">
          <span class="r2c1"
            >'{{ days[day] }} {{ date }} {{ months[month] }}'</span
          >
          &nbsp;
          <span class="r2c1">Seems a good start!</span>
        </div>
        <div v-else>
          <span class="r2c1">Select the start date for your plan</span>
        </div>
      </div>
      <div
        class="col col-lg-4 col-md-3 parent"
        style="padding: 3px 12px;"
      ></div>
    </div>

    <div class="row" style="margin: 0px 12px;">
      <div
        class="col col-lg-2 col-md-1 parent"
        style="padding: 3px 12px;"
      ></div>
      <div class="col col-lg-8 col-md-10 parent" style="padding: 3px 12px;">
        <div class="row" style="margin: 0px;">
          <div class="col-lg-2" style="padding: 0px;">
            <div class="dayRep currentDay"></div>
            &nbsp;
            <span>Current Day</span>
          </div>
          <div class="col-lg-5" style="padding: 0px;">
            <div class="dayRep availableDays"></div>
            &nbsp;
            <span>Available days to start the plan</span>
          </div>
          <div class="col-lg-5" style="padding: 0px;">
            <div class="dayRep selectedDay"></div>
            &nbsp;
            <span>Selected start date for the plan</span>
          </div>
        </div>
      </div>
      <div
        class="col col-lg-2 col-md-1 parent"
        style="padding: 3px 12px;"
      ></div>
    </div>
    <br v-if="windowWidth > 1266" />

    <div class="row" style="margin: 0px 12px;">
      <div
        class="col col-lg-3 col-md-1 parent"
        style="padding: 3px 12px;"
      ></div>
      <div class="col col-lg-6 col-md-10 parent" style="padding: 3px 12px;">
        <div class="dateContainer">
          <Calendar
            style="width: 80%;"
            :availableDates="dates"
            @selectedDate="dateSelected"
          />
        </div>
      </div>
      <div
        class="col col-lg-3 col-md-1 parent"
        style="padding: 3px 12px;"
      ></div>
    </div>
    <br v-if="windowWidth > 1266" />

    <div class="row" style="margin: 0px 12px;">
      <div class="col col-lg-12 col-sm-12" style="padding: 3px 12px;">
        <button class="p1b1" @click="doneSelectedDate()">
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Calendar from "./Components/Calendar";

export default {
  components: {
    Calendar,
  },
  created() {
    // this.allAvailableDates = this.fakeMeal.dates.map(date => {
    //   let day = date.getDate(), month = date.getMonth(), year = date.getFullYear()
    //   return `${year}-${month}-${day}`
    // })

    this.setupAvailableDates();
  },
  data: () => ({
    color: "#5ecbf0",
    oldDate: [],
    dates: [],
    days: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    day: "",
    date: null,
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    month: null,
    selectedDate: "",
    // fakeMeal: {
    //   dates: [new Date(2021, 2, 1), new Date(2021, 2, 3), new Date(2021, 2, 4), new Date(2021, 2, 5)]
    // },
    // allAvailableDates: []
  }),
  computed: {
    windowWidth() {
      return this.$store.getters.getAppWindowWidth;
    },
    windowHeight() {
      return this.$store.getters.getAppWindowHeight;
    },
  },
  methods: {
    dateSelected({ year, month, date, day, fullDate }) {
      console.log(year, month, date, day, fullDate);
      this.month = month;
      this.date = date;
      this.day = day;
      this.selectedDate = fullDate;
    },
    setupAvailableDates() {
      let now = new Date(),
        day = now.getDate(),
        month = now.getMonth(),
        year = now.getFullYear(),
        notDone = true,
        dates = [];

      // console.log(this.allAvailableDates)
      while (notDone) {
        day++;

        let date = new Date(year, month, day),
          year1 = date.getFullYear(),
          month1 = date.getMonth(),
          day1 = date.getDate(),
          weekDay = date.getDay();
        // console.log(date, `${year1}-${month1}-${day1}`, this.allAvailableDates.includes(`${year1}-${month1}-${day1}`))

        // if ((!(weekDay === 5 || weekDay === 6)) && this.allAvailableDates.includes(`${year1}-${month1}-${day1}`)) {
        if (!(weekDay === 5 || weekDay === 6)) {
          dates.push(`${year1}-${month1 + 1}-${day1}`);
          // console.log(dates)

          if (dates.length === 3) {
            this.dates = dates;

            notDone = false;
          }
        }
      }
    },
    doneSelectedDate() {
      typeof this.selectedDate === "string"
        ? this.$emit("selectedDate", this.selectedDate)
        : null;
    },
  },
};
</script>

<style scoped>
.dayRep {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: inline-block;
}

.currentDay {
  background-color: white;
  border: 2px solid #ff8c00;
}

.availableDays {
  /* background-Color: #5ecbf0; */
  border: 2px solid #5ecbf0;
}

.selectedDay {
  background-color: #f26722;
  border: 2px solid #f26722;
}

.dateContainer {
  width: 100%;
  min-height: 200px;
  border: 2px solid #edf0f2;
  border-radius: 10px;
}

.r2c1 {
  font-size: 17px;
}
</style>
