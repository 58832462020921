<template>
  <div class="pt-5 fullHeight" v-if="waiting">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
    <br />
    <span>Waiting for Transaction Response</span>
  </div>
  <div class="pt-5 fullHeight" v-else>
    <div class="row" style="margin: 0px 12px;">
      <div class="col col-lg-12 col-sm-12" style="padding: 3px 12px;">
        <span style="font-size: 22px;">Choose The Diet</span>
      </div>
    </div>

    <br />
    <div class="row" style=" padding: 3px 20px; margin: 0px 12px;">
      <div class=" col-lg-3 col-sm-6 col-xs-12">
        <div class="card" @click="selected('PROTEIN+')">
          <span class="diet_title">PROTEIN+</span>
          <img src="@/assets/images/Plate.png" />
          <span class="diet_title1">PROTEIN+</span>
          <div class="card1">
            <span class="diet_details_header">100EGP</span>
            <br />
            <span class="diet_details_priceDesc">Start price per meal</span>
            <br />
            <p class="diet_details_details">
              Packed with flavor, variety and protein heavy.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-xs-12">
        <div class="card" @click="selected('KETO')">
          <span class="diet_title">KETO</span>
          <img src="@/assets/images/Plate.png" />
          <span class="diet_title1">KETO</span>
          <div class="card1">
            <span class="diet_details_header">100EGP</span>
            <br />
            <span class="diet_details_priceDesc">Start price per meal</span>
            <br />
            <p class="diet_details_details">
              Packed with flavor, variety and protein heavy.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-xs-12">
        <div class="card" @click="selected('PELO')">
          <span class="diet_title">PALEO</span>
          <img src="@/assets/images/Plate.png" />
          <span class="diet_title1">PALEO</span>
          <div class="card1">
            <span class="diet_details_header">100EGP</span>
            <br />
            <span class="diet_details_priceDesc">Start price per meal</span>
            <br />
            <p class="diet_details_details">
              Packed with flavor, variety and protein heavy.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-xs-12">
        <div class="card" @click="selected('VEGETARIAN')">
          <span class="diet_title">VEGETARIAN</span>
          <img src="@/assets/images/Plate.png" />
          <span class="diet_title1">VEGETARIAN</span>
          <div class="card1">
            <span class="diet_details_header">100EGP</span>
            <br />
            <span class="diet_details_priceDesc">Start price per meal</span>
            <br />
            <p class="diet_details_details">
              Packed with flavor, variety and protein heavy.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    waiting: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    selected(value) {
      return this.$emit("chooseDietType", value);
    },
  },
};
</script>

<style scoped>
.diet_title {
  font-size: 30px;
  font-weight: bold;
  color: #e6e8eb;
  margin-bottom: 20px;
}

.diet_title1 {
  font-size: 15px;
}

.diet_title ~ img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.diet_details_header {
  color: #5ecbf0;
}

.diet_details_priceDesc {
  color: #818181;
  font-size: 12px;
}

.diet_details_details {
  width: 100%;
  font-size: 12px;
  overflow-wrap: break-word;
  padding: 10px;
}

.card {
  width: 100%;
  height: 100%;
  background-color: #f7f9fc;
  border-radius: 40px;
  cursor: pointer;
  text-align: center;
}

.card:hover .diet_title {
  color: #f26722;
}

.card:hover {
  box-shadow: 0 10px 30px #f7f9fc;
  transition: 0.5s;
}

.card1 {
  width: 80%;
  background-color: white;
  border-radius: 10px;
  margin: auto;
}
</style>
