import { usersCollection, storageRef } from '@/config/firebase'
import store from '../store'

export default class UserService {

  getUser(key) {
    return new Promise(async function(resolve, reject) {
      try {
        store.dispatch('admin/addLoading')
        const doc = await usersCollection
          .doc(key)
          .get()

        store.dispatch('admin/subLoading')
        if (doc.exists) {
          resolve(doc.data())
        } else {
          reject('No such document!')
        }
        
      } catch (err) {
        console.log(err)
        store.dispatch('admin/subLoading')
        reject(err)
      }
    })
  }
  
  saveUser(user) {
    return new Promise(async function(resolve, reject) {
      try {
        store.dispatch('admin/addLoading')
        usersCollection
          .add(user)
          .then(function(docRef) {
            store.dispatch('admin/subLoading')
            // console.log("Document written with ID: ", docRef.id);
            resolve(docRef.id)
          })
          .catch(function(error) {
            store.dispatch('admin/subLoading')
            store.dispatch('admin/addError', error)
            // console.error("Error adding document: ", error);
            reject(error)
          });
      } catch (err) {
        store.dispatch('admin/subLoading')
        store.dispatch('admin/addError', err)
        console.log(err)
        reject(err)
      }
    })
  }

}