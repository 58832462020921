import { mealsCollection, storageRef } from '@/config/firebase'
import store from '../store'

export default class MealService {

  getNextMeals(limit, lastRecord) {
    return new Promise(async function(resolve, reject) {
      try {
        const data = await mealsCollection
          .orderBy('mealName')
          .limit(limit)
          .startAfter(lastRecord)

        resolve(data)
      } catch (err) {
        console.log(err)
        reject(err)
      }
    })
  }

  getLimitedMeals(limit) {
    return new Promise(async function(resolve, reject) {
      try {
        const data = await mealsCollection
          .orderBy('mealName')
          .limit(limit)
          .get()

        resolve(data)
      } catch (err) {
        console.log(err)
        reject(err)
      }
    })
  }

  getMeal(key) {
    return new Promise(async function(resolve, reject) {
      try {
        store.dispatch('admin/addLoading')
        const doc = await mealsCollection
          .doc(key)
          .get()

        store.dispatch('admin/subLoading')
        if (doc.exists) {
          resolve(doc.data())
        } else {
          reject('No such document!')
        }
        
      } catch (err) {
        console.log(err)
        store.dispatch('admin/subLoading')
        reject(err)
      }
    })
  }

  getMeals() {
    return new Promise(async function(resolve, reject) {
      try {
        store.dispatch('admin/addLoading')
        const data = await mealsCollection
          .get()

        let meals = []
        data.forEach(doc => {
          meals.push({...doc.data(), key: doc.id})
        })
        console.log(meals);

        store.dispatch('admin/subLoading')
        resolve(meals)
      } catch (err) {
        console.log(err)
        store.dispatch('admin/subLoading')
        reject(err)
      }
    })
  }
  
  saveMeal(meal) {
    return new Promise(async function(resolve, reject) {
      try {
        store.dispatch('admin/addLoading')
        mealsCollection
          .add(meal)
          .then(function(docRef) {
            store.dispatch('admin/subLoading')
            // console.log("Document written with ID: ", docRef.id);
            resolve(docRef.id)
          })
          .catch(function(error) {
            store.dispatch('admin/subLoading')
            store.dispatch('admin/addError', error)
            // console.error("Error adding document: ", error);
            reject(error)
          });
      } catch (err) {
        store.dispatch('admin/subLoading')
        store.dispatch('admin/addError', err)
        console.log(err)
        reject(err)
      }
    })
  }
  
  updateMeal(key, data) {
    return new Promise(async function(resolve, reject) {
      try {
        store.dispatch('admin/addLoading')
        mealsCollection
          .doc(key)
          .update(data)
          .then(() => {
            console.log("Document successfully updated!");
            store.dispatch('admin/subLoading')
            // console.log("Document written with ID: ", docRef.id);
            resolve("Document successfully updated!")
          })
          .catch(function(error) {
            store.dispatch('admin/subLoading')
            store.dispatch('admin/addError', error)
            // console.error("Error updating document: ", error);
            reject(error)
          });
      } catch (err) {
        store.dispatch('admin/subLoading')
        store.dispatch('admin/addError', err)
        console.log(err)
        reject(err)
      }
    })
  }
  
  deleteMeal(key, meal) {
    return new Promise(async function(resolve, reject) {
      try {
        store.dispatch('admin/addLoading')
        await mealsCollection
          .doc(key)
          .delete();

        // deleteing nutritional image
        var desertRef = storageRef.child(meal.nutritionalImagePath);
        
        store.dispatch('admin/addLoading')
        desertRef.delete().then(() => {
          store.dispatch('admin/subLoading')
          console.log("Nutritional image is deleted")
        }).catch(error => {
          store.dispatch('admin/subLoading')
          console.log("Couldn't detele nutritional image", error)
        });

        // deleteing other images
        meal.mealImages.forEach((imgObj, index) => {
          let desertRef2 = storageRef.child(imgObj.imagePath);
          
          store.dispatch('admin/addLoading')
          desertRef2.delete().then(() => {
            store.dispatch('admin/subLoading')
            console.log(`${index} image is deleted`)
          }).catch(error => {
            store.dispatch('admin/subLoading')
            console.log(`Couldn't detele ${index} image`, error)
          });
        })
          
        store.dispatch('admin/subLoading')
        resolve()

      } catch (err) {
        store.dispatch('admin/subLoading')
        store.dispatch('admin/addError', err)
        console.log(err)
        reject(err)
      }
    })
  }
}