
import state from './authState.js'
import * as mutations from './authMutations.js'
import * as actions from './authActions.js'
import * as getters from './authGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}