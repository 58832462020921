<template>
  <div>
    <MealForm
      :disabledArr="disabledArr"
    />
  </div>
</template>

<script>
import MealForm from '../Shared_Components/Meals/MealForm'

export default {
  components: {
    MealForm
  },
  data: () => ({
    disabledArr: []
  })
}
</script>

<style>

</style>