<template>
  <div class="fullHeight">
    <div class="row" id="row1" style="margin: 0px 12px;">
      <div class="col col-lg-12 col-sm-12" style="padding: 3px 12px;">
        <!-- <img src="@/assets/images/mealprep-illus.png" class="pl-2">
        <img src="@/assets/images/mealprep-illus-1.png" class="pl-2">
        <img src="@/assets/images/mealprep-illus-2.png" class="pl-2"> -->
        <!-- <SlideYUpTransition origin="center" :styles="styles" :duration="250">
        <img v-if="imgStep === 1" src="@/assets/images/mealprep-illus.png" class="pl-2">
        <img v-else-if="imgStep === 2" src="@/assets/images/mealprep-illus-1.png" class="pl-2">
        <img v-else-if="imgStep === 3" src="@/assets/images/mealprep-illus-2.png" class="pl-2">
        </SlideYUpTransition> -->

        <transition name="slide-fade">
          <img
            v-if="imgStep === 1"
            src="@/assets/images/mealprep-illus.png"
            class="pl-2"
          />
          <img
            v-else-if="imgStep === 2"
            src="@/assets/images/mealprep-illus-1.png"
            class="pl-2"
          />
          <img
            v-else-if="imgStep === 3"
            src="@/assets/images/mealprep-illus-2.png"
            class="pl-2"
          />
        </transition>
      </div>
    </div>

    <div class="row" id="row2" style="margin: 0px 12px;">
      <div class="col col-lg-12 col-sm-12" style="padding: 3px 12px;">
        <p>YOUR MEALS WILL BE READY AND FRESH ON YOUR PLANNED DAYS!</p>
      </div>
    </div>

    <div class="row" id="row3" style="margin: 0px 12px;">
      <div class="col col-lg-12 col-sm-12" style="padding: 3px 12px;">
        <p>Thank you for ordering with us</p>
      </div>
    </div>

    <div class="row" style="margin: 0px 12px;">
      <div class="col col-lg-6 col-sm-6" style="padding: 3px 12px;">
        <button
          class="p1b1"
          style="float: right;"
          @click="$emit('selectedOption', 1)"
        >
          Back Home
        </button>
      </div>
      <div class="col col-lg-6 col-sm-6" style="padding: 3px 12px;">
        <button
          class="p1b1"
          style="float: left; background-color: white; border: 2px solid #F26722; color: #F26722;"
          @click="$emit('selectedOption', 2)"
        >
          View Orders
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { SlideYUpTransition } from "vue2-transitions";

export default {
  components: {
    SlideYUpTransition,
  },
  created() {
    this.activeStep = 2;
    this.imgStep = 1;
  },
  data: () => ({
    imgStep: 0,
    activeStep: 0,
    styles: {
      type: Object,
      default: () => {
        return {
          animationFillMode: "both",
          animationTimingFunction: "ease-out",
        };
      },
    },
  }),
  watch: {
    imgStep(val) {
      // console.log(val)

      if (val === 0) {
        // setTimeout(() => {
        // this.activeStep === 3 ? this.activeStep = 1 : this.activeStep++
        this.activeStep === 2 ? (this.activeStep = 3) : (this.activeStep = 2);
        this.imgStep = this.activeStep;
        // }, 250)
      } else if (val === 1) {
        setTimeout(() => {
          this.imgStep = 0;
        }, 300);
      } else {
        setTimeout(() => {
          this.imgStep = 0;
        }, 600);
      }
    },
  },
};
</script>

<style scoped>
#row1 {
  padding-top: 7%;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.6s ease-in-out;
}
.slide-fade-leave-active {
  transition: all 0.6s ease-in-out cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}

#row2 {
  padding-top: 5%;
  font-size: 25px;
}

#row3 {
  font-size: 20px;
}
</style>
