import store from '@/store'

export function mealRequest (that, from) {

  let dates = [], caloriesLow = 0, caloriesHigh = 0

  if (that.meal.dates)
    dates = that.meal.dates.map(date => {
      let a = date.split('-')
      if (a.length === 3) {
        return new Date(a[0], a[1] - 1, a[2], 0, 0, 0)
      } else {
        return new Date(date)
      }
    })
  if (that.meal.caloriesLow)
    caloriesLow = parseInt(that.meal.caloriesLow)
    if (that.meal.caloriesHigh)
      caloriesHigh = parseInt(that.meal.caloriesHigh)
  
  var meal = {
    data: { 
      ...that.meal, 
      dates, 
      caloriesLow, 
      caloriesHigh
    },
    nutritionalImage: that.nutritionalImage,
    images: that.images
  }

  console.log(meal, from)
  that.mealService
    .saveMeal(meal.data)
    .then(key => {
      console.log(key)
      
      if (meal.nutritionalImage && meal.nutritionalImage.name) {
        let filePath = 'uploads/meals/' + key + '/nutritionalImage/' + meal.nutritionalImage.name
        that.commonsService
          .postImage(filePath, meal.nutritionalImage)
          .then(downloadURL => {
            console.log(filePath, downloadURL)
  
            that.mealService
              .updateMeal(key, {
                'nutritionalImage': downloadURL,
                'nutritionalImagePath': filePath
              })
              .then(data => {
                console.log(data)
              })
              .catch(err => console.log(err))
  
          })
          .catch(err => console.log(err))
      }

      if (meal.images && meal.images[0]) {
        store.dispatch('admin/meal/setProgressList', meal.images.map(a => 0))
        var urls = []
        
        meal.images.forEach((image, index) => {
          if (image && image.name) {
            let filePath = 'uploads/meals/' + key + '/images/' + image.name
            that.commonsService
              .postImage(filePath, image, index)
              .then(downloadURL => {
                console.log(filePath, downloadURL)

                urls.push({
                  'image': downloadURL,
                  'imagePath': filePath
                })

                if (index === meal.images.length - 1) {
      
                  that.mealService
                    .updateMeal(key, {
                      'mealImages': urls
                    })
                    .then(data => {
                      console.log(data)
                      that.posting = false
                      that.$router.back()
                    })
                    .catch(err => console.log(err))

                }
      
              })
              .catch(err => console.log(err))
          }
        })
      }

    })
    .catch(err => console.log(err))
}