<template>
  <div id="nav" class="header sticky">
    <v-toolbar class="header">
      <!-- <v-container> -->
      <v-container>
        <div v-if="windowWidth >= 992">
          <div class="row">
            <div class="col-xs-12 col-lg-12 ">
              <div class="header-wrapper">
                <!-- <v-toolbar-title>Brisk</v-toolbar-title> -->
                <div @click="goHome()" class="main-logo">
                  <img src="@/assets/images/imgs/brisk.png" />
                </div>
                <div class="nav">
                  <ul>
                    <li><a class="item active">TODAY MEAL</a></li>
                    <li><a class="item">PACKAGES</a></li>
                    <li><a class="item">FAQ</a></li>
                    <li><a class="item">ABOUT US</a></li>
                    <li><a class="item">CONTACT US</a></li>
                  </ul>
                </div>
                <div>
                  <div class="header__shopping">
                    <a class="header__shopping__basket">
                      <i class="fas fa-shopping-cart"></i>
                      <span>3</span>
                    </a>
                    <a class="header__shopping__user">
                      <i class="far fa-user"></i>
                      <span>Charles Chavez</span>
                    </a>
                    <div class="dropdown-content">
                      <span
                        v-if="
                          $store.getters['auth/getProfile'] &&
                            ($store.getters['auth/getProfile'].uid ||
                              $store.getters['auth/getProfile'].key)
                        "
                        @click="logout()"
                        href="#"
                      >
                        Logout
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="mobile-menu-wrapper">
          <div class="row" style="padding-top: 7px;">
            <div class="col col-lg-2 col-md-2 col-xs-2 col-sm-2">
              <div @click="goHome()" style="float:left; cursor: pointer;">
                <img src="@/assets/images/logo.png" />
              </div>
            </div>
            <div class="col col-lg-10 col-md-10 col-xs-10 col-sm-10">
              <div style="float:right;">
                <img src="@/assets/images/cart-ic-21.png" class="pr-6" />

                <div
                  class="dropdown"
                  style="float:right; padding-right: 70px; z-index: 100;"
                >
                  <button class="dropbtn">
                    <v-icon>mdi-chevron-down</v-icon>
                  </button>
                  <div class="dropdown-content">
                    <span
                      v-if="
                        $store.getters['auth/getProfile'] &&
                          ($store.getters['auth/getProfile'].uid ||
                            $store.getters['auth/getProfile'].key)
                      "
                      @click="logout()"
                      href="#"
                    >
                      Logout
                    </span>
                  </div>
                </div>

                <div
                  style="display: inline; float:right; position: absolute; right: 50px;"
                >
                  <input type="checkbox" id="check" v-model="menu" />
                  <label for="check" class="checkbtn">
                    <v-icon>mdi-menu</v-icon>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="mobile-menu"  v-if="menu">
          <div class="row" style="background-color: white; ">
            <div class="col col-lg-12">
              <ul class="smallUL">
                <li><a class="px-3 active">TODAY MEAL</a></li>
                <li><a class="px-3">PACKAGES</a></li>
                <li><a class="px-3">FAQ</a></li>
                <li><a class="px-3">ABOUT US</a></li>
                <li><a class="px-3">CONTACT US</a></li>
              </ul>
            </div>
            </div>
          </div>
        </div>

        <!-- <v-toolbar-title>Client</v-toolbar-title>

        <span class="px-6"><router-link to="/">Client</router-link></span>

        <span class="px-6"><router-link to="/admin">Admin</router-link></span> -->
      </v-container>
    </v-toolbar>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";

export default {
  data: () => ({
    menu1: false,
  }),
  computed: {
    menu: {
      set(val) {
        this.menu1 = val;
      },
      get() {
        return this.menu1 && this.windowWidth < 1250;
      },
    },
    windowWidth() {
      return this.$store.getters.getAppWindowWidth;
    },
  },
  watch: {
    // windowWidth(val) {
    //   console.log(val)
    // }
  },
  methods: {
    goHome() {
      this.$store.dispatch("client/setCurrentPage", "home");
    },
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$store.dispatch("auth/setFirebaseProfile", null);
          this.$store.dispatch("auth/setMyProfile", null);
        });
    },
  },
};
</script>

<style scoped>
/* .dropdown {
  float: left;
  overflow: hidden;
} */

.dropdown .dropbtn {
  border: none;
  outline: none;
  color: black;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content span {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: inherit;
  cursor: pointer;
}

.dropdown-content span:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

#check {
  display: none;
}

#nav {
  height: 60px;
  box-shadow: 0 10px 30px #8d8d8d;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 9;
  width: 100%;
}

.nav ul {
  float: right;
}

.nav ul li {
  display: inline;
  line-height: 30px;
  margin: 0 5px;
}

/* .nav ul li a {
  font-size: 13px;
  padding: 7px 13px;
  border-radius: 3px;
} */

.nav ul li a + :active,
.nav ul li a:hover {
  background-color: #f26722;
  color: #fff;
  border-radius: 3px;
  transform: scale(1.02);
}

.smallUL {
  list-style: none;
  cursor: pointer;
}

.smallUL li {
  line-height: 30px;
  margin: 0 5px;
}

.smallUL li span {
  font-size: 13px;
  padding: 7px 13px;
  border-radius: 3px;
}

.smallUL li span:active,
.smallUL li span:hover {
  background-color: #f26722;
}

.v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: none;
}
/**********/
.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.v-toolbar__content {
  padding: 0 !important;
}
.nav ul li a {
  color: #282e49;
  font-size: calc(12px + (14 - 12) * ((100vw - 992px) / (1366 - 992)));
  margin-right: 35px;
  padding: 5px;
  transition: 0.3s;
}

.header__shopping a {
  color: #000;
  cursor: pointer;
  display: inline-block;
}
.header__shopping a i {
  font-size: 24px;
}
.header__shopping a i:hover {
  color: #000;
  text-decoration: none;
}

.header__shopping__basket {
  position: relative;
  margin-right: 30px;
}
.header__shopping__basket:hover span {
  transition: 0.2s;
}

.header__shopping__basket:hover span:hover {
  transform: scale(1.08);
}

.header__shopping__basket span {
  position: absolute;
  top: -5px;
  right: -3px;
  z-index: 2;
  background: orange;
  color: #fff;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 0 0px;
}

.header__shopping__user span {
  margin-left: 10px;
  display: inline-block;
}
.main-logo {
  cursor: pointer;
}
</style>
