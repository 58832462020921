import { render, staticRenderFns } from "./ThankYouForOrdering.vue?vue&type=template&id=0bfc55c3&scoped=true&"
import script from "./ThankYouForOrdering.vue?vue&type=script&lang=js&"
export * from "./ThankYouForOrdering.vue?vue&type=script&lang=js&"
import style0 from "./ThankYouForOrdering.vue?vue&type=style&index=0&id=0bfc55c3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bfc55c3",
  null
  
)

export default component.exports