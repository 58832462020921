// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
// import "firebase/functions";

// TODO: Replace the following with your app's Firebase project configuration
// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
var firebaseConfig = {
  apiKey: "AIzaSyC5GzEEcgyr1uXaEBOoGrQwunyvE7fE1C4",
  authDomain: "brisk-b8c45.firebaseapp.com",
  projectId: "brisk-b8c45",
  storageBucket: "brisk-b8c45.appspot.com",
  messagingSenderId: "499679914181",
  appId: "1:499679914181:web:cf2145d2e8e9ef195ce8ac",
  measurementId: "G-TJMKCNWQQX"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// firebase.functions().useEmulator("localhost", 9098);

// utils
const auth = firebase.auth()
const storage =  firebase.storage()
const storageRef =  storage.ref()
const db = firebase.firestore()
// if (location.hostname === "localhost") {
//   db.useEmulator("localhost", 9097);
// }

// collection references
const mealsCollection = db.collection('meals')
const ordersCollection = db.collection('orders')
const usersCollection = db.collection('users')

// export utils/refs
export {
  db,
  auth,
  storage,
  storageRef,
  mealsCollection,
  ordersCollection,
  usersCollection
}