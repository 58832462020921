<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="ordersToShow"
      :search="search"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
    >
      
      <template
        v-slot:body="{ items }"
      >
        <tbody>
          <tr
            v-for="item in items"
            :key="item.name"
          >
            <td>{{ item.dietType }}</td>
            <td>{{ item.caloriesToShow }}</td>
            <td>{{ item.mealDays }}</td>
            <td>{{ item.mealTimesToShow }}</td>
            <td>{{ item.mealDatesToShow }}</td>
            <td>{{ item.snacksLength }}</td>
            <td>
              <v-chip
                v-if="item.orderStatus === 'Completed'"
                class="ma-2"
                color="green"
                text-color="white"
              >
                {{ item.orderStatus }}
              </v-chip>
              <v-chip
                v-else-if="item.orderStatus === 'Paid'"
                class="ma-2"
                color="primary"
                text-color="white"
              >
                {{ item.orderStatus }}
              </v-chip>
              <v-chip
                v-else-if="item.orderStatus === 'Unpaid'"
                class="ma-2"
                color="red"
                text-color="white"
              >
                {{ item.orderStatus }}
              </v-chip>
              <v-chip
                v-else-if="item.orderStatus === 'Paid and Building Calendar'"
                class="ma-2"
                color="secondary"
              >
                {{ item.orderStatus }}
              </v-chip>
              <v-chip
                v-else-if="item.orderStatus === 'Paid and Processing'"
                class="ma-2"
              >
                {{ item.orderStatus }}
              </v-chip>
            </td>
            <td>
              <v-icon
                @click="$emit('view', item)"
              >
                mdi-information
              </v-icon>
            </td>
            <td>
              <v-icon
                :disabled="requesting"
                @click="$emit('delete', item)"
              >
                mdi-delete
              </v-icon>
            </td>
          </tr>
        </tbody>
      </template>

    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    orders: {
      type: Array,
      required: true
    },
    search: {
      type: String,
      required: false,
      default: ''
    },
    requesting: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      page: 1,
      itemsPerPage: 5,
      pageCount: null,
      headers: [
        {
          text: 'Diet',
          align: 'left',
          sortable: false,
          value: 'dietType',
        },
        { text: 'Calories', align: 'left', value: 'caloriesToShow' },
        { text: 'Meal Days', align: 'left', value: 'mealDays' },
        { text: 'Meal Times', align: 'left', value: 'mealTimesToShow' },
        { text: 'Meal Dates', align: 'left', value: 'mealDatesToShow' },
        { text: 'No. of snacks', align: 'left', value: 'snacksLength' },
        { text: 'Order Status', align: 'left', value: 'orderStatus' },
      ],
      ordersToShow: []
    }
  },
  watch: {
    orders(val) {
      this.setupDataTable(val)
    },
    page(val) {
      // console.log(val)
    },
    pageCount(val) {
      // console.log(val)
    }
  },
  methods: {
    setupDataTable(orders) {
      this.ordersToShow = []
      
      orders.forEach(order => {

        if (order && order.key) {
          let a = {
            ...order
          }
          if (order.calories.low && order.calories.high) {
            a.caloriesToShow = `${order.calories.low}~${order.calories.high}`
          }
          if (order.mealTimes) {
            let b = ''
            order.mealTimes.forEach(c => {
              b = b.concat(`${c}, `)
            })
            a.mealTimesToShow = b.slice(0, b.length - 2)
          }
          if (order.mealsPerDay) {
            let b = ''
            order.mealsPerDay.forEach(c => {
              b = b.concat(`${c.date},  `)
            })
            a.mealDatesToShow = b.slice(0, b.length - 2)
          }
          a.snacksLength = order.snacks.length
          this.ordersToShow.push(a)
        }
      });
    }
  }
}
</script>

<style>

</style>