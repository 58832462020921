
const actions = {
  setAppDrawer({ commit }, data )  {
    commit('SET_APP_DRAWER', data)
  },

  addError({ commit, getters }, value){
    console.log(getters, typeof getters.getErrors)
    var errors = [...getters.getErrors]
    errors.push(value)
    commit('ERRORS', value)
  },

  setErrors({ commit }, value){
    commit('ERRORS', value)
  },

  addLoading({ commit, getters }){
    var loading = getters.getLoading
    loading++
    commit('LOADING', loading)
  },

  subLoading({ commit, getters }){
    var loading = getters.getLoading
    loading--
    commit('LOADING', loading)
  },

  setLoading({ commit }, value){
    if (typeof value === 'number')
      commit('LOADING', value)
  },

  resetLoading({ commit }){
    commit('LOADING', 0)
  }
}

export default actions