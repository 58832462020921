<template>
  <v-card>
    <v-dialog
      v-model="deleteWarning"
      width="500"
    >
      <v-card>
        <v-card-title class="headline lighten-2">
          Delete warning
        </v-card-title>

        <v-card-text>
          Do you really want to delete this meal?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="requesting"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-btn
            v-else
            color="error"
            text
            @click="deleteMeal()"
          >
            Yes
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="deleteWarning = false"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        dark
        class="mb-2"
        @click="$router.push({ name: 'adminAddMeal' })"
      >
        Add meal
      </v-btn>
    </v-card-title>

    <MealsTable
      :meals="meals"
      :search="search"
      :requesting="requesting"
      @view="viewMeal"
      @delete="deleteMealWarning"
    />
  </v-card>
</template>

<script>
import MealService from '@/services/mealservice'

import MealsTable from '../Shared_Components/Meals/MealsTable'

export default {
  components: {
    MealsTable
  },
  created() {
    console.log('Meals table created.')

    this.getMeals()
  },
  data () {
    return {
      mealService: new MealService(),
      meals: [],
      search: '',
      requesting: false,
      deleteWarning: false,
      meal: {}
    }
  },
  methods: {
    viewMeal(meal) {
      console.log(meal)
      if (meal.key) {
        this.$router.push(`/admin/meal/${meal.key}`)
      }
    },
    deleteMealWarning(meal) {
      console.log(meal)
      this.meal = meal
      this.deleteWarning = true
    },
    deleteMeal() {
      if (this.meal && this.meal.key) {
        this.requesting = true

        this.mealService
          // .getLimitedMeals(this.itemsPerPage)
          .deleteMeal(this.meal.key, this.meal)
          .then(data => {
            this.requesting = false

            this.meals = this.meals.filter(a => a.key !== this.meal.key)
            this.deleteWarning = false
          })
          .catch(error => {
            this.requesting = false
            console.log(error)
          })

      }
    },
    getMeals() {
      this.meals = []
      this.requesting = true

      this.mealService
        // .getLimitedMeals(this.itemsPerPage)
        .getMeals()
        .then(data => {
          this.requesting = false
          this.meals = data
        })
        .catch(error => {
          this.requesting = false
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>

tr {
  height: 100px;
}

td {
  padding: 0px 0px;
}

td img {
  height: 100%;
}

</style>