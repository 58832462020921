import firebase from 'firebase/app'
import 'firebase/auth'

export const getProfile = state => {
  if (state.myProfile && state.myProfile.key) {
    return {
      ...state.myProfile,
      ...state.firebaseProfile
    }
  } else {
    if (localStorage.getItem("profile")) {
      return {
        ...JSON.parse(localStorage.getItem("profile")),
        ...state.firebaseProfile
      }
    } else {
      return null
    }
  }
}

export const getMyProfile = state => {
  if (state.myProfile && state.myProfile.key) {
    return state.myProfile
  } else {
    if (localStorage.getItem("profile")) {
      return {
        ...JSON.parse(localStorage.getItem("profile"))
      }
    } else {
      return null
    }
  }
}

export const getFirebaseProfile = state => {
  return state.firebaseProfile
}

export const getMyToken = state => {
  return state.myToken || localStorage.getItem("user-token")
}

export const getMyRefreshToken = state => {
  return state.myRefreshToken || localStorage.getItem("user-refresh-token")
}

export const getNextRefreshTime = state => {
  return state.sendNextRefreshRequestIn
}