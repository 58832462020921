<template>
  <div class="container">
    <ProfileView
      :user="profile"
    />
  </div>
</template>

<script>
import ProfileView from './ProfileView'

export default {
  components: {
    ProfileView
  },
  computed: {
    profile() {
      return this.$store.getters['auth/getProfile']
    }
  }
}
</script>

<style>

</style>