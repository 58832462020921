import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

import auth from './auth'
import admin from './admin'
import client from './client'

export default new Vuex.Store({
  strict: true,
  state,
  actions,
  mutations,
  getters,
  modules: {
    auth,
    admin,
    client
  }
})
