
import state from './clientState.js'
import * as mutations from './clientMutations.js'
import * as actions from './clientActions.js'
import * as getters from './clientGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}