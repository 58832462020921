
const actions = {
  setAppWindowWidth({ commit }, value) {
    if (typeof value === 'number') {
      commit('APP_WINDOW_WIDTH', value)
    }
  },
  setAppWindowHeight({ commit }, value) {
    if (typeof value === 'number') {
      commit('APP_WINDOW_HEIGHT', value)
    }
  }
}

export default actions