<template>
  <v-navigation-drawer
    v-model="drawer"
    absolute
    bottom
    temporary
  >
    <v-list
      nav
      dense
    >
      <v-list-item-group
        v-model="group"
        active-class="deep-purple--text text--accent-4"
      >
        <v-list-item @click="$router.push({ name: 'adminHome' })">
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>

        <v-list-item @click="$router.push({ name: 'adminMeals' })">
          <v-list-item-title>Meals</v-list-item-title>
        </v-list-item>
        
        <v-list-item @click="$router.push({ name: 'adminOrders' })">
          <v-list-item-title>Orders</v-list-item-title>
        </v-list-item>
        
        <v-list-item @click="$router.push({ name: 'adminUser' })">
          <v-list-item-title>Users</v-list-item-title>
        </v-list-item>

      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data: () => ({
    group: null,
  }),
  computed: {
    drawer: {
      set(val) {
        this.$store.dispatch('admin/setAppDrawer', val)
      },
      get() {
        return this.$store.getters['admin/getAppDrawer']
      }
    }
  },
  watch: {
    group () {
      this.drawer = false
    },
  },
}
</script>

<style>

</style>