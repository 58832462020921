
export const setMyProfile = ({ commit }, obj ) => {
  commit('MY_PROFILE', obj)
  localStorage.setItem('profile', JSON.stringify(obj))
}

export const setFirebaseProfile = ({ commit }, obj ) => {
  commit('FIREBASE_PROFILE', obj)
}

export const setMyToken = ({ commit }, str ) => {
  commit('MY_TOKEN', str)
  localStorage.setItem('user-token', str)
}

export const setMyRefreshToken = ({ commit }, str ) => {
  commit('MY_REFRESH_TOKEN', str)
  localStorage.setItem('user-refresh-token', str)
}

export const setNextRefreshTime = ({ commit }, str ) => {
  commit('NEXT_REFRESH_TIME', str)
}