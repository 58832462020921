<template>
  <div class="overflow-x">
    <div class="row py-8">
  
      <div class="parent">
        <div class="tabs-wrapper">
          <div
            class="tab"
            id="tab1"
            @click="!checkoutRequired ? $emit('setActive', 1) : ''"
            :style="{ cursor: checkoutRequired ? 'auto' : 'pointer' }"
          >
            <div class="img-wrapping">
              <img
                id="tabImg1"
                class="tabimg"
                src="@/assets/images/imgs/right-check.png"
                :style="{ opacity: checkoutRequired ? '0.5' : '1' }"
              />
              <img
                class="tabImg2 main-icon"
                src="@/assets/images/meal-ic-147.png"
                :style="{ opacity: checkoutRequired ? '0.5' : '1' }"
              />
            </div>
            <span class="tabLabel" id="tabLabel1">
              Choose The Diet
            </span>
          </div>

          <!-- <div class="" id="after1"><hr class="between" /></div> -->
          <div
            class="tab"
            id="tab2"
            @click="!checkoutRequired ? $emit('setActive', 2) : ''"
            :style="{ cursor: checkoutRequired ? 'auto' : 'pointer' }"
          >
            <div class="img-wrapping">
              <img
                id="tabImg2"
                class="tabimg"
                src="@/assets/images/imgs/right-check.png"
                :style="{ opacity: checkoutRequired ? '0.5' : '1' }"
              />
              <img
                class="tabImg2 main-icon"
                src="@/assets/images/calories-ic-73.png"
                :style="{ opacity: checkoutRequired ? '0.5' : '1' }"
              />
            </div>
            <span class="tabLabel" id="tabLabel2">
              Target Calories
            </span>
          </div>

          <!-- <div class="betweenParent" id="after2"><hr class="between" /></div> -->
          <div
            class="tab"
            id="tab3"
            @click="!checkoutRequired ? $emit('setActive', 3) : ''"
            :style="{ cursor: checkoutRequired ? 'auto' : 'pointer' }"
          >
            <div class="img-wrapping">
              <img
                id="tabImg3"
                class="tabimg"
                src="@/assets/images/imgs/right-check.png"
                :style="{ opacity: checkoutRequired ? '0.5' : '1' }"
              />
              <img
                class="tabImg2 main-icon"
                src="@/assets/images/meal-ic-141.png"
                :style="{ opacity: checkoutRequired ? '0.5' : '1' }"
              />
            </div>
            <span class="tabLabel" id="tabLabel3">
              Choose Meals & Days
            </span>
          </div>

          <!-- <div class="betweenParent" id="after3"><hr class="between" /></div> -->
          <div
            class="tab"
            id="tab4"
            @click="!checkoutRequired ? $emit('setActive', 4) : ''"
            :style="{ cursor: checkoutRequired ? 'auto' : 'pointer' }"
          >
            <div class="img-wrapping">
              <img
                id="tabImg4"
                class="tabimg"
                src="@/assets/images/imgs/right-check.png"
                :style="{ opacity: checkoutRequired ? '0.5' : '1' }"
              />
              <img
                class="tabImg2 main-icon"
                src="@/assets/images/snacks-ic.png"
                :style="{ opacity: checkoutRequired ? '0.5' : '1' }"
              />
            </div>

            <span class="tabLabel" id="tabLabel4">
              ADDITIONAL Snacks
            </span>
          </div>

          <!-- <div class="betweenParent" id="after4"><hr class="between" /></div> -->
          <div
            class="tab"
            id="tab5"
            @click="$emit('setActive', 11)"
            style="cursor: pointer;"
          >
            <div class="img-wrapping">
              <img
                id="tabImg5"
                src="@/assets/images/imgs/right-check.png"
                class="tabimg"
              />
              <img
                class="tabImg2 main-icon"
                src="@/assets/images/checkout-ic-51.png"
              />
            </div>
            <span class="tabLabel" id="tabLabel5">
              Checkout
            </span>
          </div>

          <!-- <div class="betweenParent" id="after5"><hr class="between" /></div> -->
          <div
            class="tab"
            id="tab6"
            @click="$emit('setActive', 6)"
            style="cursor: pointer;"
          >
            <div class="img-wrapping">
              <img
                id="tabImg6"
                src="@/assets/images/imgs/right-check.png"
                class="tabimg"
              />
              <img
                class="tabImg2 main-icon"
                src="@/assets/images/calendars-ic-71.png"
              />
            </div>
            <span class="tabLabel" id="tabLabel6">
              Setup Your Meals Calendar
            </span>
          </div>
        </div>

        <!-- <div
        class="col col-lg-2 col-md-0 col-sm-0 col-xs-0"
        style="padding: 3px 12px;"
      ></div> -->
      </div>
    </div>
    <br />
    <br />
  </div>
</template>

<script>
export default {
  props: ["active", "checkoutRequired"],
  mounted() {
    this.removeTicks();
    this.adjustTabLabelsWidth();
    this.adjustTabLabelsHeight();
  },
  computed: {
    windowWidth() {
      return this.$store.getters.getAppWindowWidth;
    },
    windowHeight() {
      return this.$store.getters.getAppWindowHeight;
    },
  },
  watch: {
    windowWidth(val) {
      // console.log(val)

      this.adjustTabLabelsWidth();
      this.adjustTabLabelsHeight();
    },
    windowHeight(val) {
      // console.log(val)

      this.adjustTabLabelsHeight();
    },
  },
  methods: {
    // adjustTabLabelsWidth() {
    //   for (let i = 1; i <= 6; i++) {
    //     let a = document.getElementById("tab" + i);
    //     if (a) {
    //       let b = a.getBoundingClientRect();
    //       document.getElementById("tabLabel" + i).style.left =
    //         b.left - 20 + "px";
    //       document.getElementById("tabImg" + i).style.left = b.left + 10 + "px";
    //     }
    //   }
    // },
    // adjustTabLabelsHeight() {
    //   for (let i = 1; i <= 6; i++) {
    //     let a = document.getElementById("tab" + i);
    //     if (a) {
    //       let b = a.getBoundingClientRect();
    //       document.getElementById("tabLabel" + i).style.top = b.top + 70 + "px";
    //       document.getElementById("tabImg" + i).style.top = b.top - 20 + "px";
    //     }
    //   }
    // },
    removeTicks() {
      for (let i = 1; i <= 6; i++) {
        let a = document.getElementById("tab" + i);
        a.style.border = "2px solid white";
        let b = document.getElementById("after" + i);
        // console.log(i, b)
        if (b) {
          // console.log(b.children[0])
          b.children[0].style.border = "2px solid #D9E6FF";
          b.children[0].style.backgroundColor = "#D9E6FF";
        }
        let c = document.getElementById("tabImg" + i);
        if (c) {
          c.style.display = "none";
        }
      }

      let a = document.getElementById("tab1");
      // console.log(a)
      if (a) {
        a.style.border = "2px solid #F26722";
        a.style.backgroundColor = "#F26722";
      }
    },
  },
};
</script>

<style scoped>
.tab {
  width: 60px;
  height: 60px;
  background-color: white;
  border-radius: 50px;
  border: 2px solid #5ecbf0;
  margin: 0;
  display: inline-block;
  position: relative;
}

/* #tabImg1 {
  position: absolute;
  top: 9.2%;
  left: 25.6%;
}

#tabImg2 {
  position: absolute;
  top: 9.2%;
  left: 34.7%;
}

#tabImg3 {
  position: absolute;
  top: 9.2%;
  left: 44%;
}

#tabImg4 {
  position: absolute;
  top: 9.2%;
  left: 53.2%;
}

#tabImg5 {
  position: absolute;
  top: 9.2%;
  left: 62.4%;
}

#tabImg6 {
  position: absolute;
  top: 9.2%;
  left: 71.6%;
} */

.betweenParent {
  display: inline-block;
  width: 80px;
  padding-bottom: 12px;
  /* background-color: #5ecbf0; */
}

.between {
  border: 1px #5ecbf0 solid;
  background-color: #5ecbf0;
}

/* .tabImg2 {
  height: 75%;
  padding-top: 10px;
  padding-left: 1px;
} */

.tabLabel {
  position: absolute;
  width: 110px;
}

/* #tabLabel1 {
  top: 20%;
  left: 23.5%;
}

#tabLabel2 {
  top: 20%;
  left: 32.6%;
}

#tabLabel3 {
  top: 20%;
  left: 41.9%;
}

#tabLabel4 {
  top: 20%;
  left: 51.2%;
}

#tabLabel5 {
  top: 20%;
  left: 60.3%;
}

#tabLabel6 {
  top: 20%;
  left: 69.9%;
} */
.tabLabel {
  top: 105%;
  left: -30px;
  text-align: center;
  color: #fff;
}
.img-wrapping {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.img-wrapping .main-icon {
  width: 30px;
  height: 30px;
}
.tabs-wrapper {
  display: flex;
  position: relative;
}
.tabs-wrapper .tab {
  margin-right: 80px;
}
.tabs-wrapper .tab:last-child {
  margin-right: 0;
}
.tabs-wrapper::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 5px;
  width: 100%;
  background: #765f87;
  content: "";
}
.py-8 {
  display: flex;
  justify-content: center;
}
.tabimg {
  position: absolute;
  left: 80%;
  top: 4px;
}
</style>
