<template>
  <div>
    <v-dialog
      v-model="deleteWarning"
      width="500"
    >
      <v-card>
        <v-card-title class="headline lighten-2">
          Delete warning
        </v-card-title>

        <v-card-text>
          Do you really want to delete this meal?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="requesting"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-btn
            v-else
            color="error"
            text
            @click="deleteMeal()"
          >
            Yes
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="deleteWarning = false"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="row">
      <div class="col col-lg-12 col-sm-12 left">
        <div style="float: right;">
          <v-btn
            depressed
            color="error"
            @click="deleteWarning = true"
          >
            Delete Meal
          </v-btn>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col col-lg-2 col-sm-4 left">
        <strong>Meal Name</strong>
      </div>
      <div class="col col-lg-10 col-sm-8 left">
        {{ meal.mealName }}
      </div>
    </div>

    <div class="row">
      <div class="col col-lg-2 col-sm-4 left">
        <strong>Meal Type</strong>
      </div>
      <div class="col col-lg-10 col-sm-8 left">
        {{ meal.mealType }}
      </div>
    </div>

    <div class="row">
      <div class="col col-lg-2 col-sm-4 left">
        <strong>Calories Low</strong>
      </div>
      <div class="col col-lg-10 col-sm-8 left">
        {{ meal.caloriesLow }}
      </div>
    </div>

    <div class="row">
      <div class="col col-lg-2 col-sm-4 left">
        <strong>Calories High</strong>
      </div>
      <div class="col col-lg-10 col-sm-8 left">
        {{ meal.caloriesHigh }}
      </div>
    </div>

    <div class="row">
      <div class="col col-lg-2 col-sm-4 left">
        <strong>Ingredients</strong>
      </div>
      <div class="col col-lg-10 col-sm-8 left">
        <ul>
          <li
            v-for="(ingredient, index) in meal.ingredients"
            :key="index"
          >{{ ingredient }}</li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col col-lg-2 col-sm-4 left">
        <strong>Allowed Dates</strong>
      </div>
      <div class="col col-lg-10 col-sm-8 left">
        <ul>
          <li
            v-for="(date, index) in meal.dates"
            :key="index"
          >{{ date.toDate() }}</li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col col-lg-2 col-sm-4 left">
        <strong>Meal Description</strong>
      </div>
      <div class="col col-lg-10 col-sm-8 left">
        {{ meal.description }}
      </div>
    </div>

    <div class="row">
      <div class="col col-lg-2 col-sm-4 left">
        <strong>Nutritional Image</strong>
      </div>
      <div class="col col-lg-10 col-sm-8 left">
        <img :src="meal.nutritionalImage" style="maxWidth: 200px; maxHeight: 200px;"/>
      </div>
    </div>

    <div class="row">
      <div class="col col-lg-2 col-sm-4 left">
        <strong>Meal Images</strong>
      </div>
      <div class="col col-lg-10 col-sm-8 left">
        <img v-for="(image, index) in meal.mealImages" :key="index" :src="image.image" style="maxWidth: 200px; maxHeight: 200px;"/>
      </div>
    </div>
  </div>
</template>

<script>
import MealService from '@/services/mealservice'

export default {
  created() {
    console.log(this.$route.params.id)

    this.getMeal()
  },
  data: () => ({
    mealService: new MealService(),
    requesting: false,
    meal: {},
    deleteWarning: false
  }),
  methods: {
    deleteMeal() {
      this.requesting = true

      this.mealService
        // .getLimitedMeals(this.itemsPerPage)
        .deleteMeal(this.$route.params.id, this.meal)
        .then(data => {
          this.requesting = false

          this.$router.push(`/admin/meals`)
        })
        .catch(error => {
          this.requesting = false
          console.log(error)
        })

    },
    getMeal() {
      this.requesting = true
      
      this.mealService
        .getMeal(this.$route.params.id)
        .then(data => {
          this.requesting = false
          this.meal = data
          console.log(this.meal)
        })
        .catch(error => {
          this.requesting = false
          console.log(error)
        })
    }
  }
}
</script>

<style>

.left {
  text-align: left;
}

</style>