<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="mealsToShow"
      :search="search"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
    >
      
      <template
        v-slot:body="{ items }"
      >
        <tbody>
          <tr
            v-for="item in items"
            :key="item.name"
          >
            <td>{{ item.mealName }}</td>
            <td>{{ item.calories }}</td>
            <td>{{ item.mealType }}</td>
            <td>{{ item.ingredientsToShow }}</td>
            <td>
              <img 
                :src="item.mealImages && item.mealImages[0] ? item.mealImages[0].image : ''"
                style="max-height: 100px;"
              />
            </td>
            <td>
              <v-icon
                @click="$emit('view', item)"
              >
                mdi-information
              </v-icon>
            </td>
            <td
              v-if="!hiddenArray.includes('delete')"
            >
              <v-icon
                :disabled="requesting"
                @click="$emit('delete', item)"
              >
                mdi-delete
              </v-icon>
            </td>
          </tr>
        </tbody>
      </template>

    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    meals: {
      type: Array,
      required: true
    },
    search: {
      type: String,
      required: false,
      default: ''
    },
    requesting: {
      type: Boolean,
      required: false,
      default: false
    },
    hiddenArray: {
      type: Array,
      required: false,
      default: function() {
        return []
      }
    }
  },
  data () {
    return {
      page: 1,
      itemsPerPage: 5,
      pageCount: null,
      headers: [
        {
          text: 'Name',
          align: 'left',
          sortable: false,
          value: 'mealName',
        },
        { text: 'Calories', align: 'left', value: 'calories' },
        // { text: 'Description', value: 'description' },
        // { text: 'Description To Show', value: 'descriptionToShow' },
        { text: 'Meal Type', align: 'left', value: 'mealType' },
        { text: 'Ingredients', align: 'left', value: 'ingredientsToShow' },
        { img: 'Meal Image', align: 'left', value: '' },
      ],
      mealsToShow: []
    }
  },
  watch: {
    meals(val) {
      // console.log(val)
      this.setupDataTable(val)
    },
    page(val) {
      // console.log(val)
    },
    pageCount(val) {
      // console.log(val)
    }
  },
  methods: {
    setupDataTable(meals) {
      this.mealsToShow = []

      meals.forEach(meal => {

        if (meal && meal.key) {
          let a = {
            ...meal,
            descriptionToShow: '',
            ingredientsToShow: ''
          }
          if (meal.caloriesLow && meal.caloriesHigh) {
            a.calories = `${meal.caloriesLow}~${meal.caloriesHigh}`
          }
          if (meal.description) {
            if (meal.description.length > 100) {
              a.descriptionToShow = meal.description.slice(0, 100)
            } else {
              a.descriptionToShow = meal.description
            }
          }
          if (meal.ingredients) {
            let b = ''
            meal.ingredients.forEach(c => {
              b = b.concat(`${c}, `)
            })
            a.ingredientsToShow = b.slice(0, b.length - 2)
          }
          this.mealsToShow.push(a)
        }
      });
    }
  }
}
</script>

<style>

</style>