<template>
  <div class="container">
    My Orders
    <br />
    <br />
    <br />

    <Orders
      v-if="orders"
      :orders="orders"
      :customOrders="true"
    />
  </div>
</template>

<script>
import OrderService from '@/services/orderService'

import Orders from '../../Admin/Orders/Orders'

export default {
  components: {
    Orders
  },
  created() {
    this.getMyOrders()
  },
  data: () => ({
    orderService: new OrderService(),
    orders: []
  }),
  methods: {
    getMyOrders() {
      console.log(this.$store.getters['auth/getProfile'], this.$store.getters['auth/getMyProfile'])
      if (this.$store.getters['auth/getProfile'] && this.$store.getters['auth/getProfile'].key) {
        this.orderService
          .getMyOrders()
          .then(data => {
            console.log(data)
            this.orders = data
          })
          .catch(error => {
            console.log(error)
          })
      }
    }
  }
}
</script>

<style>

</style>