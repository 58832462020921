var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header sticky",attrs:{"id":"nav"}},[_c('v-toolbar',{staticClass:"header"},[_c('v-container',[(_vm.windowWidth >= 992)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-lg-12 "},[_c('div',{staticClass:"header-wrapper"},[_c('div',{staticClass:"main-logo",on:{"click":function($event){return _vm.goHome()}}},[_c('img',{attrs:{"src":require("@/assets/images/imgs/brisk.png")}})]),_c('div',{staticClass:"nav"},[_c('ul',[_c('li',[_c('a',{staticClass:"item active"},[_vm._v("TODAY MEAL")])]),_c('li',[_c('a',{staticClass:"item"},[_vm._v("PACKAGES")])]),_c('li',[_c('a',{staticClass:"item"},[_vm._v("FAQ")])]),_c('li',[_c('a',{staticClass:"item"},[_vm._v("ABOUT US")])]),_c('li',[_c('a',{staticClass:"item"},[_vm._v("CONTACT US")])])])]),_c('div',[_c('div',{staticClass:"header__shopping"},[_c('a',{staticClass:"header__shopping__basket"},[_c('i',{staticClass:"fas fa-shopping-cart"}),_c('span',[_vm._v("3")])]),_c('a',{staticClass:"header__shopping__user"},[_c('i',{staticClass:"far fa-user"}),_c('span',[_vm._v("Charles Chavez")])]),_c('div',{staticClass:"dropdown-content"},[(
                        _vm.$store.getters['auth/getProfile'] &&
                          (_vm.$store.getters['auth/getProfile'].uid ||
                            _vm.$store.getters['auth/getProfile'].key)
                      )?_c('span',{attrs:{"href":"#"},on:{"click":function($event){return _vm.logout()}}},[_vm._v(" Logout ")]):_vm._e()])])])])])])]):_c('div',{staticClass:"mobile-menu-wrapper"},[_c('div',{staticClass:"row",staticStyle:{"padding-top":"7px"}},[_c('div',{staticClass:"col col-lg-2 col-md-2 col-xs-2 col-sm-2"},[_c('div',{staticStyle:{"float":"left","cursor":"pointer"},on:{"click":function($event){return _vm.goHome()}}},[_c('img',{attrs:{"src":require("@/assets/images/logo.png")}})])]),_c('div',{staticClass:"col col-lg-10 col-md-10 col-xs-10 col-sm-10"},[_c('div',{staticStyle:{"float":"right"}},[_c('img',{staticClass:"pr-6",attrs:{"src":require("@/assets/images/cart-ic-21.png")}}),_c('div',{staticClass:"dropdown",staticStyle:{"float":"right","padding-right":"70px","z-index":"100"}},[_c('button',{staticClass:"dropbtn"},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1),_c('div',{staticClass:"dropdown-content"},[(
                      _vm.$store.getters['auth/getProfile'] &&
                        (_vm.$store.getters['auth/getProfile'].uid ||
                          _vm.$store.getters['auth/getProfile'].key)
                    )?_c('span',{attrs:{"href":"#"},on:{"click":function($event){return _vm.logout()}}},[_vm._v(" Logout ")]):_vm._e()])]),_c('div',{staticStyle:{"display":"inline","float":"right","position":"absolute","right":"50px"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.menu),expression:"menu"}],attrs:{"type":"checkbox","id":"check"},domProps:{"checked":Array.isArray(_vm.menu)?_vm._i(_vm.menu,null)>-1:(_vm.menu)},on:{"change":function($event){var $$a=_vm.menu,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.menu=$$a.concat([$$v]))}else{$$i>-1&&(_vm.menu=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.menu=$$c}}}}),_c('label',{staticClass:"checkbtn",attrs:{"for":"check"}},[_c('v-icon',[_vm._v("mdi-menu")])],1)])])])]),(_vm.menu)?_c('div',{staticClass:"mobile-menu"},[_c('div',{staticClass:"row",staticStyle:{"background-color":"white"}},[_c('div',{staticClass:"col col-lg-12"},[_c('ul',{staticClass:"smallUL"},[_c('li',[_c('a',{staticClass:"px-3 active"},[_vm._v("TODAY MEAL")])]),_c('li',[_c('a',{staticClass:"px-3"},[_vm._v("PACKAGES")])]),_c('li',[_c('a',{staticClass:"px-3"},[_vm._v("FAQ")])]),_c('li',[_c('a',{staticClass:"px-3"},[_vm._v("ABOUT US")])]),_c('li',[_c('a',{staticClass:"px-3"},[_vm._v("CONTACT US")])])])])])]):_vm._e()])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }