<template>
  <div class="pa-3">

    <v-row no-gutters >
      <v-col
        cols="12"
        lg="6"
        sm="6"
      >
        <v-text-field
          label="First Name"
          :rules="[required]"
          v-model="firstName"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        sm="6"
      >
        <v-text-field
          label="Last Name"
          :rules="[required]"
          v-model="lastName"
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row no-gutters >
      <v-col
        cols="12"
        lg="6"
        sm="6"
      >
        <v-text-field
          label="Phone Number"
          :rules="[phone]"
          v-model="phoneNo"
          hide-details="auto"
        >
          <v-icon
            slot="prepend"
          >
            +20
          </v-icon>
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        sm="6"
      >
        <v-text-field
          label="Email"
          :rules="[required, emailAddress]"
          v-model="email"
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row no-gutters >
      <v-col
        cols="12"
        lg="6"
        sm="6"
      >
        <v-text-field
          label="Password"
          :rules="[required, min6Chars]"
          v-model="password"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        sm="6"
      >
        <v-text-field
          label="Confirm Password"
          :rules="[required, min6Chars]"
          v-model="confirmPassword"
          hide-details="auto"
        ></v-text-field>
        <small v-if="password !== confirmPassword" style="color: red;">Passwords should match</small>
      </v-col>
    </v-row>

    <v-row no-gutters >
      <v-col
        cols="12"
        lg="12"
        sm="12"
      >
        <v-text-field
          label="Address"
          :rules="[required]"
          v-model="address"
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row no-gutters >
      <v-col
        cols="12"
        lg="12"
        sm="12"
      >
        <v-progress-circular
          v-if="requesting"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <v-btn
          v-else
          depressed
          color="primary"
          @click="signup"
        >
          Signup
        </v-btn>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import UserService from '@/services/userService'

import { usersCollection } from '@/config/firebase'

import phone from 'phone';

export default {
  data: () => ({
    userService: new UserService(),
    firstName: '',
    lastName: '',
    phoneNo: '',
    email: '',
    password: '',
    confirmPassword: '',
    address: '',
    requesting: false,
    finalUserFailed: false,
    finalUserSuccess: false,
    finalUser: {}
  }),
  watch: {
    requesting(val) {
      if (val) {
        this.$emit('requesting', val)
      }
    },
    finalUserFailed(val) {
      if (val) {
        this.$emit('signup-failed')
      }
    },
    finalUserSuccess(val) {
      if (val) {
        this.$emit('signup-success', { ...this.finalUser, password: this.password })
      }
    }
  },
  methods: {
    // Rules
    required: value => {
      return !!value || 'Required'
    },
    min6Chars: value => {
      return (value && value.length >= 6) || 'Min 6 characters'
    },
    num: value => {
      return /^\d+$/.test(value) || 'Only digits are allowed'
    },
    phone: value => {
      if (value.length) {
        return phone('+20' + value).length ? true : 'Invalid phone number'
      } else {
        return true
      }
    },
    emailAddress: value => {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(value) || 'Invalid email address'
    },
    samePasswords() {
      return this.password === this.confirmPassword || 'Passwords should match'
    },

    signup() {
      if (
        this.firstName &&
        this.lastName &&
        this.password === this.confirmPassword && 
        this.email &&
        this.emailAddress(this.email)
      ) {
        let obj = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          password: this.password,
          address: this.address,
          newUser: true
        }
        if (phone('+20' + this.phoneNo).length) {
          obj.phoneNo = '+20' + this.phoneNo
        }

        console.log(obj)
        
        this.requesting = true
        this.finalUserFailed = false
        this.finalUserSuccess = false
        this.finalUser = {}

        this.userService
          .saveUser(obj)
          .then(data => {
            console.log(data)

            this.getUser(data)
          })
          .catch(error => {
            this.requesting = false
            this.finalUserFailed = true
            this.finalUserSuccess = false
            console.log(error)
          })

      }
    },
    getUser(key) {
      usersCollection.doc(key)
        .onSnapshot((doc) => {
          console.log("Current data: ", doc.data());
          if (doc.data()) {
            if (doc.data().uid) {
              this.finalUser = { ...doc.data(), key: doc.data().uid }
              this.requesting = false
              this.finalUserFailed = false
              this.finalUserSuccess = true
            }

          } else {
            if (!this.finalUserSuccess) {
              console.log("User already exists, unable to add user.")

              this.requesting = false
              this.finalUserFailed = true
            }
          }
        });
    }
  }
}
</script>

<style>

</style>