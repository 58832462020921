<template>
  <div class="pt-5 fullHeight">
    <div v-if="step === 1">

      <div class="row" style="margin: 0px 12px;">
        <div class="col col-lg-12 col-sm-12" style="padding: 3px 12px;">
          <div v-if="responseMessage === 'Filed Transaction'">
            <br />
            <br />
            <h2>TRANSACTION FAILED</h2>
          </div>
          <div v-else-if="(orderInFirestore && orderInFirestore.paymentToken && orderInFirestore.paymentTokenExpiration && orderInFirestore.paymentTokenExpiration > new Date().getTime())">
            <div v-if="!iframeLoaded">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
              <br />
              <span>Almost done</span>
            </div>
            <iframe 
              :src="'https://accept.paymob.com/api/acceptance/iframes/156691?payment_token=' + orderInFirestore.paymentToken"
              id="paymobIFrame"
              height="450px;"
              width="100%"
              frameborder="0"
              @load="load"
              ref="frame"
            ></iframe>
          </div>
          <div v-else>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
            <br />
            <span>Creating order</span>
          </div>
        </div>
      </div>
      
    </div>
    <div v-else-if="step === 2">
      <div class="row" style="height: 500px;">
        <div class="col col-lg 12">
          <br />
          <br />
          <br />
          <br />
          <br />
          <img src="@/assets/images/checkmark-ic-320.png">
          <br />
          <br />
          <h2>THANK YOU</h2>
          <span>You are ready to setup your meal calendar.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    orderInFirestore: {
      type: Object,
      required: false
    },
    responseMessage: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: () => ({
    step: 1,
    iframeLoaded: false
  }),
  created() {
    if (this.orderInFirestore) {
      this.checkForPaid()
    }
  },
  watch: {
    orderInFirestore() {
      this.checkForPaid()
    }
  },
  methods: {
    checkForPaid() {
      if (this.orderInFirestore) {
        if (this.orderInFirestore.orderStatus === 'Paid') {
          this.nextStep()
        }
      }
    },
		load: function(){
      console.log('iframe loaded')
      var frame = this.$refs.frame
      console.log(frame)
      if (
        frame
      ) {
        // console.log(frame.children)
        // setTimeout(() => {
        //   console.log(frame.contentDocument)
        // }, 3000);
        
        this.iframeLoaded = true
      }
    },
    nextStep() {
      this.step = 2
      setTimeout(() => {
        this.$emit('checkOutDone')
      }, 2000)
    }
  }
}
</script>

<style scoped>

#paymobIFrame #document html body header {
  display: none;
}

</style>