<template>
  <div>
    <v-dialog
      v-model="deleteWarning"
      width="500"
    >
      <v-card>
        <v-card-title class="headline lighten-2">
          Delete warning
        </v-card-title>

        <v-card-text>
          Do you really want to delete this order?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="requesting"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-btn
            v-else
            color="error"
            text
            @click="deleteOrder()"
          >
            Yes
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="deleteWarning = false"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="row">
      <div class="col col-lg-12 col-sm-12 left">
        <div style="float: right;">
          <v-btn
            depressed
            color="error"
            @click="deleteWarning = true"
          >
            Delete Order
          </v-btn>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col col-lg-2 col-sm-4 left">
        <strong>Diet Type</strong>
      </div>
      <div class="col col-lg-10 col-sm-8 left">
        {{ order.dietType }}
      </div>
    </div>

    <div class="row">
      <div class="col col-lg-2 col-sm-4 left">
        <strong>Meal Days</strong>
      </div>
      <div class="col col-lg-10 col-sm-8 left">
        {{ order.mealDays }}
      </div>
    </div>

    <div class="row">
      <div class="col col-lg-2 col-sm-4 left">
        <strong>Calories</strong>
      </div>
      <div class="col col-lg-10 col-sm-8 left">
        {{ order && order.calories ? `${order.calories.low}~${order.calories.high}` : '' }}
      </div>
    </div>

    <div class="row">
      <div class="col col-lg-2 col-sm-4 left">
        <strong>Start Date</strong>
      </div>
      <div class="col col-lg-10 col-sm-8 left">
        {{ order && order.startDate ? order.startDate.toDate() : '' }}
      </div>
    </div>

    <div class="row">
      <div class="col col-lg-2 col-sm-4 left">
        <strong>Meal Times</strong>
      </div>
      <div class="col col-lg-10 col-sm-8 left">
        <ul>
          <li
            v-for="(time, index) in order.mealTimes"
            :key="index"
          >{{ time }}</li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col col-lg-2 col-sm-4 left">
        <strong>Meal Details</strong>
      </div>
      <div class="col col-lg-12 col-sm-12 left">
        <ul>
          <li
            v-for="(meal, index) in order.mealsPerDay"
            :key="index"
          >
            <strong>{{ meal.date }}</strong>

            <MealTable
              :meals="mealsPerDate[index] && Array.isArray(mealsPerDate[index]) ? mealsPerDate[index] : []"
              :hiddenArray="['delete']"
              @view="viewMeal"
            />
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col col-lg-2 col-sm-4 left">
        <strong>Snacks</strong>
      </div>
      <div class="col col-lg-10 col-sm-8 left">
        <ul>
          <SnacksTable
            :snacks="order.snacks"
          />
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
import OrderService from '@/services/orderService'
import MealService from '@/services/mealservice'

import MealTable from '../Shared_Components/Meals/MealsTable'
import SnacksTable from '../Shared_Components/Orders/SnacksTable'

export default {
  components: {
    MealTable,
    SnacksTable
  },
  created() {
    console.log(this.$route.params.id)

    this.getOrder()
  },
  data: () => ({
    orderService: new OrderService(),
    mealService: new MealService(),
    requesting: false,
    order: {},
    mealsKeys: [],
    mealsValues: [],
    mealsPerDate: [],
    deleteWarning: false
  }),
  methods: {
    deleteOrder() {
      this.requesting = true

      this.orderService
        .deleteOrder(this.$route.params.id, this.order)
        .then(data => {
          this.requesting = false

          this.$router.push(`/admin/orders`)
        })
        .catch(error => {
          this.requesting = false
          console.log(error)
        })

    },
    viewMeal(meal) {
      // console.log(meal)
      if (meal.key) {
        this.$router.push(`/admin/meal/${meal.key}`)
      }
    },
    prepareMealsList() {
      if (this.order.mealsPerDay) {
        this.mealsPerDate = []

        this.order.mealsPerDay.forEach((meal, index) => {
          this.mealsPerDate.push([])

          Object.keys(meal).forEach(key => {
            if (key !== 'date' && meal[key]) {
              let index1 = this.mealsKeys.findIndex(me => me === meal[key])
              this.mealsPerDate[index].push(this.mealsValues[index1])
            }
          })
        })
        // console.log(this.mealsPerDate)
      }
    },
    getOrder() {
      this.requesting = true
      
      this.orderService
        .getOrder(this.$route.params.id)
        .then(data => {
          this.requesting = false
          this.order = {...data}
          console.log(this.order)

          if (data.mealsPerDay.length) {
            data.mealsPerDay.forEach((meal, index) => {
              // console.log(meal)

              Object.keys(meal).forEach(key => {
                if (key !== 'date') {
                  if (!this.mealsKeys.includes(meal[key])) {
                    this.mealsKeys.push(meal[key])
                    this.mealsValues.push({})

                    this.mealService
                      .getMeal(meal[key])
                      .then(data => {
                        let index = this.mealsKeys.findIndex(a => a === meal[key])
                        this.mealsValues[index] = { ...data, key: meal[key] }

                        this.prepareMealsList()
                      })
                      .catch(error => {
                        console.log(error)
                      })
                  }
                }
              })

            })
          }

        })
        .catch(error => {
          this.requesting = false
          console.log(error)
        })
      console.log(this.mealsKeys, this.mealsValues)
    }
  }
}
</script>

<style>

.left {
  text-align: left;
}

</style>