
export const UPLOADING = (state, value) => {
  state.uploading = value
}

export const UPLOAD_PROGRESS = (state, value) => {
  state.uploadProgress = value
}

export const PROGRESS_LIST = (state, value) => {
  state.progressList = value
}