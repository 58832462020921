import { storageRef } from '@/config/firebase'
import store from '../store'

export default class CommonsService {

  postImage(filePath, image, index = null) {
    return new Promise(async function(resolve, reject) {
      try {
        store.dispatch('admin/addLoading')

        const spaceRef = storageRef.child(filePath);
        const uploadTask = spaceRef.put(image)

        uploadTask.on('state_changed', function(snapshot){
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log('Upload is ' + progress + '% done')
          // console.log(progress, index)

          if (index === null) {
            if (progress === 100) {
              store.dispatch('admin/meal/setUploading', false)
            } else {
              store.dispatch('admin/meal/setUploading', true)
            }
            store.dispatch('admin/meal/setUploadProgress', progress)
          } else {
            store.dispatch('admin/meal/setProgressInList', { value: progress, index })
          }

        }, function(error) {
          console.log("Unsuccessful upload", error)
          dispatch('addError', "Upload is unsuccessful.")
          // Handle unsuccessful uploads
        });
        
        uploadTask.then(function(fileData) {
          // console.log('Uploaded! ', fileData)
          spaceRef.getDownloadURL().then(function(downloadURL) {
            store.dispatch('admin/subLoading')
            resolve(downloadURL)
          })
        })

      } catch (err) {
        store.dispatch('admin/subLoading')
        store.dispatch('admin/addError', err)

        console.log(err)
        reject(err)
      }
    })
  }
}