
const mutations = {
  SET_APP_DRAWER(state, value) {
    state.appDrawer = value
  },
  
  ERRORS (state, value) {
    state.errors = value
  },

  LOADING (state, value) {
    state.loading = value
  }
}

export default mutations