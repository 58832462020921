import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  myProfile: {},
  firebaseProfile: null,
  myToken: '',
  myRefreshToken: '',
  sendNextRefreshRequestIn: new Date().getTime()
}
